import React, { ChangeEvent, useState } from 'react';
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
// Helpers
import { parseForInnerHTMLWithBreaks } from 'helpers/textEditor';
// API
import {
  APPROVE_DELIVERABLES,
  REJECT_DELIVERABLES,
} from 'api/campaignV2/mutations';
// Types
import {
  ApproveDeliverables,
  ApproveDeliverablesVariables,
} from 'api/campaignV2/types/ApproveDeliverables';
import {
  RejectDeliverables,
  RejectDeliverablesVariables,
} from 'api/campaignV2/types/RejectDeliverables';
import { GetCampaignOffers_getCampaignOffers_entities_deliverables } from 'api/campaignV2/types/GetCampaignOffers';
// UiShared
import TextArea from 'uiShared/Textarea/Textarea';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './CampaignDeliverablesSubmissionReview.module.scss';

type CampaignDeliverablesSubmissionReviewProps = {
  deliverable: GetCampaignOffers_getCampaignOffers_entities_deliverables;
  offerId: string;
  ownerId: string;
  onFinish: () => void;
};

const CampaignDeliverablesSubmissionReview = ({
  deliverable,
  offerId,
  ownerId,
  onFinish,
}: CampaignDeliverablesSubmissionReviewProps): JSX.Element => {
  const [agencyFeedback, setAgencyFeedback] = useState<string>('');
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  const [approveDeliverable, { loading: approveLoading }] = useMutation<
    ApproveDeliverables,
    ApproveDeliverablesVariables
  >(APPROVE_DELIVERABLES);

  const [rejectDeliverable, { loading: rejectLoading }] = useMutation<
    RejectDeliverables,
    RejectDeliverablesVariables
  >(REJECT_DELIVERABLES);

  const handleToggleFeedback = () => {
    setShowFeedback((previousState) => !previousState);
  };

  const handleFeedbackChange = (newValue: ChangeEvent<HTMLTextAreaElement>) => {
    const feedback = newValue.target.value;
    if (feedback !== '') {
      setAgencyFeedback(feedback);
    } else {
      setAgencyFeedback('');
    }
  };

  const deliverableId = deliverable.id;

  const handleApprove = async () => {
    try {
      await approveDeliverable({
        variables: {
          input: {
            ownerId,
            offerId,
            deliverableId,
          },
        },
      });

      successNotification('Approved successfully!');
      onFinish();
    } catch (error: any) {
      errorNotification('Something went wrong, please try again later');
    }
  };

  const handleReject = async () => {
    try {
      await rejectDeliverable({
        variables: {
          input: {
            ownerId,
            offerId,
            deliverableId,
            agencyFeedback,
          },
        },
      });

      successNotification('Rejection submitted.');
      onFinish();
    } catch (error: any) {
      errorNotification('Something went wrong, please try again later');
    }
  };

  const isLoading = approveLoading || rejectLoading;

  return (
    <div className={styles.root}>
      {showFeedback ? (
        <div>
          <p className={styles.label}>Feedback</p>
          <TextArea
            placeholder="Provide detailed, specific feedback to ensure talent revisions meet approval requirements."
            name="feedback"
            onChange={handleFeedbackChange}
            className={styles.textarea}
            rows={3}
          />
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              loading={isLoading}
              onClick={handleToggleFeedback}
              type="primary"
              ghost
            >
              Back to Deliverables
            </Button>

            <Button
              className={styles.button}
              loading={isLoading}
              onClick={handleReject}
              type="primary"
            >
              Submit Feedback
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.reviewWrapper}>
          <div className={styles.inputWrapper}>
            <p className={styles.label}>Post Copy</p>
            <p
              className={styles.value}
              dangerouslySetInnerHTML={{
                __html: parseForInnerHTMLWithBreaks(deliverable.postCopy),
              }}
            />
          </div>

          <div className={styles.previewsContainer}>
            {deliverable.files.map((file, index) => {
              const isImage = file.key?.match(/\.(jpeg|jpg|png)$/i);
              const isVideo = file.key?.match(/\.(mp4)$/i);

              return (
                <div key={index} className={styles.previewWrapper}>
                  <p className={styles.fileName}>{file.name}</p>
                  {isImage ? (
                    <img
                      src={file.url}
                      alt={file.name}
                      className={styles.preview}
                    />
                  ) : isVideo ? (
                    <video
                      src={file.url}
                      controls
                      className={styles.preview}
                      crossOrigin="anonymous"
                    >
                      <track
                        kind="captions"
                        label="English captions"
                        srcLang="en"
                        src=""
                        default
                      />
                      <source src={file.url} />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <p>Unsupported file type</p>
                  )}
                </div>
              );
            })}
          </div>

          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              loading={isLoading}
              onClick={handleApprove}
              type="primary"
            >
              Approve Deliverables
            </Button>

            <Button
              className={styles.button}
              loading={isLoading}
              onClick={handleToggleFeedback}
              type="primary"
              danger
            >
              Reject Deliverables
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignDeliverablesSubmissionReview;
