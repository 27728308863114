import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { Button } from 'antd';
// Constants
import { dateTimeFormat } from 'constants/global';
// Api
import { GET_CAMPAIGN_OFFERS } from 'api/campaignV2/queries';
import { COMPLETED_CAMPAIGN_OFFER } from 'api/campaignV2/mutations';
// Types
import { GetCampaignsV2_getCampaigns_entities } from 'api/campaignV2/types/GetCampaignsV2';
import {
  CompleteCampaignOffer,
  CompleteCampaignOfferVariables,
} from 'api/campaignV2/types/CompleteCampaignOffer';
import {
  GetCampaignOffers,
  GetCampaignOffers_getCampaignOffers_entities,
  GetCampaignOffersVariables,
} from 'api/campaignV2/types/GetCampaignOffers';
import {
  CampaignOffersOrderBy,
  CampaignOfferStatus,
  CampaignStatus,
  CampaignType,
  GetCampaignOffersInput,
  SortDirection,
} from 'api/graphql-global-types';
// Helpers
import { getSocialMediaType } from 'helpers/following';
import {
  CAMPAIGN_OFFER_STATUS_OPTIONS,
  getFollowerRange,
  mapOfferStatusToLabel,
  SOCIAL_FOLLOWERS_OPTIONS,
} from 'helpers/campaign';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import Followers from '../Followers/Followers';
import SearchByNameAndEmail from './components/SearchByNameAndEmail/SearchByNameAndEmail';
import CampaignDeliverables from './components/CampaignDeliverables/CampaignDeliverables';
import CampaignDeliverablesReview from './components/CampaignDeliverablesReview/CampaignDeliverablesReview';
// Styles
import styles from './TalentTable.module.scss';

type TalentTableProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
};

const TalentTable = ({ record }: TalentTableProps): JSX.Element | null => {
  // deliverables submission is a final step for talents, where they submit approved deliverables
  const [deliverablesSubmissionModalData, setDeliverablesSubmissionModalData] =
    useState<GetCampaignOffers_getCampaignOffers_entities | null>(null);

  // deliverables review is where review data is saved until publish
  const [deliverablesReviewModalData, setDeliverablesReviewModalData] =
    useState<GetCampaignOffers_getCampaignOffers_entities | null>(null);

  // used on deliverables review modal as a flag for child component
  const [isRevisionHistoryReadOnly, setIsRevisionHistoryReadOnly] =
    useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [sort, setSortInfo] = useState<SortedInfo<CampaignOffersOrderBy>>({
    order: SortDirection.DESC,
    key: CampaignOffersOrderBy.name,
  });

  const isSocialCampaign = record?.type === CampaignType.Social;

  const socialMediaType = record ? getSocialMediaType(record) : null;

  const getCampaignOffersInput: any = () => {
    const input: GetCampaignOffersInput = {
      campaignId: record?.id,
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      ...(isSocialCampaign && {
        socialMediaType,
      }),
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === CampaignOffersOrderBy.status) {
        return (input['statusesV2'] = [value] as CampaignOfferStatus[]);
      }
      if (key === CampaignOffersOrderBy.socialFollowingLive) {
        const result = getFollowerRange(value as string);

        return (input['socialFollowingLiveRange'] = result);
      }
    });

    return { input };
  };

  const {
    data: offerData,
    loading,
    refetch,
  } = useQuery<GetCampaignOffers, GetCampaignOffersVariables>(
    GET_CAMPAIGN_OFFERS,
    {
      variables: {
        ...getCampaignOffersInput(),
      },
      fetchPolicy: 'cache-and-network',
      skip: !record?.id,
    }
  );

  const [completeCampaignOffer] = useMutation<
    CompleteCampaignOffer,
    CompleteCampaignOfferVariables
  >(COMPLETED_CAMPAIGN_OFFER);

  const handleCloseDeliverablesSubmissionModal = () => {
    setDeliverablesSubmissionModalData(null);
  };

  const handleCloseDeliverablesReviewModal = () => {
    setDeliverablesReviewModalData(null);
  };

  const handleFinish = () => {
    handleCloseDeliverablesSubmissionModal();
    handleCloseDeliverablesReviewModal();
    refetch();
  };

  const talents = offerData?.getCampaignOffers?.entities;
  const handleCompleteCampaignOffer = useCallback(
    async (offer: GetCampaignOffers_getCampaignOffers_entities) => {
      try {
        await completeCampaignOffer({
          variables: {
            input: {
              ownerId: offer.campaign.agency.id,
              offerId: offer.id,
            },
          },
        });

        successNotification('Campaign offer completed!');
      } catch (err) {
        errorNotification((err as Error)?.message);
      }
    },
    [completeCampaignOffer]
  );

  const handleOpenDeliverablesSubmissionModal = useCallback(
    (record: GetCampaignOffers_getCampaignOffers_entities) => {
      setDeliverablesSubmissionModalData(record);
    },
    []
  );

  const handleOpenDeliverablesReviewModal = useCallback(
    (record: GetCampaignOffers_getCampaignOffers_entities) => {
      setDeliverablesReviewModalData(record);
      setIsRevisionHistoryReadOnly(false);
    },
    []
  );

  const handleOpenRevisionHistoryModal = useCallback(
    (record: GetCampaignOffers_getCampaignOffers_entities) => {
      setDeliverablesReviewModalData(record);
      setIsRevisionHistoryReadOnly(true);
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        title: 'Talent id',
        dataIndex: 'userId',
        key: CampaignOffersOrderBy.id,
        align: 'left' as const,
        width: 150,
        sorterType: 'text',
      },
      {
        title: 'Talent Name',
        dataIndex: 'profileName',
        key: CampaignOffersOrderBy.name,
        align: 'left' as const,
        width: 150,
        sorterType: 'text',
      },
      {
        title: 'Following',
        dataIndex: 'socialFollowingLive',
        key: CampaignOffersOrderBy.socialFollowingLive,
        align: 'left' as const,
        width: 150,
        sorterType: 'number',
        withRadioFilters: true,
        filters: SOCIAL_FOLLOWERS_OPTIONS,
        render: function DisplayFollowers(
          dueData: string,
          item: GetCampaignOffers_getCampaignOffers_entities
        ) {
          return <Followers row={item} />;
        },
      },
      {
        title: 'Applied Date',
        dataIndex: 'appliedAt',
        key: CampaignOffersOrderBy.appliedAt,
        align: 'left' as const,
        width: 150,
        sorterType: 'date',
        render: function DisplayDate(
          dueData: string,
          item: GetCampaignOffers_getCampaignOffers_entities
        ) {
          return (
            <span>
              {item.appliedAt
                ? moment(item.appliedAt).local().format(dateTimeFormat)
                : 'N/A'}
            </span>
          );
        },
      },
      {
        title: 'Approved Date',
        dataIndex: 'approvedAt',
        key: CampaignOffersOrderBy.approvedAt,
        align: 'left' as const,
        width: 150,
        sorterType: 'date',
        render: function DisplayDate(
          dueData: string,
          item: GetCampaignOffers_getCampaignOffers_entities
        ) {
          return (
            <span>
              {item.approvedAt
                ? moment(item.approvedAt).local().format('MM/DD/YYYY, h:mm a')
                : 'N/A'}
            </span>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: CampaignOffersOrderBy.status,
        align: 'left' as const,
        width: 150,
        withRadioFilters: true,
        filters: CAMPAIGN_OFFER_STATUS_OPTIONS,
        filterMultiple: true,
        render: function displayStatus(
          status: string,
          item: GetCampaignOffers_getCampaignOffers_entities
        ) {
          return <span>{mapOfferStatusToLabel(item.statusV2)}</span>;
        },
      },
      {
        title: 'Submission Date',
        dataIndex: 'submittedAt',
        key: CampaignOffersOrderBy.submittedAt,
        align: 'left' as const,
        width: 150,
        sorterType: 'date',
        render: function DisplayDate(
          dueData: string,
          item: GetCampaignOffers_getCampaignOffers_entities
        ) {
          return (
            <span>
              {item.submittedAt
                ? moment(item.submittedAt).local().format('MM/DD/YYYY, h:mm a')
                : 'N/A'}
            </span>
          );
        },
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        align: 'center' as const,
        width: 50,
        render: function Actions(
          id: string,
          record: GetCampaignOffers_getCampaignOffers_entities
        ) {
          const isCampaignPublished =
            record?.statusV2 === CampaignOfferStatus.PUBLISHED;

          const showViewButton =
            isCampaignPublished ||
            record?.statusV2 === CampaignOfferStatus.COMPLETED;

          const isDeliverableApproved =
            record.statusV2 === CampaignOfferStatus.DELIVERABLES_APPROVED;

          const isScheduled = record.statusV2 === CampaignOfferStatus.SCHEDULED;

          const isLiveAppearance =
            record.submissions[0]?.__typename ===
            'CampaignLiveAppearanceSubmission';

          const allowSubmitDeliverables =
            (isDeliverableApproved || isScheduled) && !isLiveAppearance;

          const isDeliverableRejected =
            record.statusV2 === CampaignOfferStatus.DELIVERABLES_REJECTED;

          const isLimitReached = record.deliverables?.length === 3;

          // revision history is read only if it is accepted or all 3 are rejected
          const isRevisionHistoryReadOnly =
            (isLimitReached && isDeliverableRejected) || isDeliverableApproved;

          // show revision history button if offer is not live appearance and history is read only
          const showRevisionHistoryButton =
            !isLiveAppearance && isRevisionHistoryReadOnly;

          // if campaign is in submitted state, that means a review is ready and waiting
          const showReviewDeliverablesButton =
            record.statusV2 === CampaignOfferStatus.SUBMITTED;

          // if campaign is in accepted or deliverables_rejected state and limit is not reached
          // we can upload new deliverables for review
          const showUploadDeliverablesForReviewButton =
            (record.statusV2 === CampaignOfferStatus.ACCEPTED ||
              isDeliverableRejected) &&
            !isLimitReached &&
            record.campaign.type !== CampaignType.SponsoredAppearance;

          return (
            <div className={styles.actions}>
              {isCampaignPublished && (
                <Button
                  key={`complete-${record.userId}`}
                  onClick={() => handleCompleteCampaignOffer(record)}
                  type="primary"
                >
                  Complete campaign offer
                </Button>
              )}

              {showRevisionHistoryButton ? (
                <Button
                  key={`submit-${record.userId}`}
                  onClick={() => handleOpenRevisionHistoryModal(record)}
                  type="primary"
                >
                  Revision History
                </Button>
              ) : null}

              {showReviewDeliverablesButton ? (
                <Button
                  key={`submit-${record.userId}`}
                  onClick={() => handleOpenDeliverablesReviewModal(record)}
                  type="primary"
                >
                  Review Deliverables
                </Button>
              ) : null}

              {showUploadDeliverablesForReviewButton ? (
                <Button
                  key={`submit-${record.userId}`}
                  onClick={() => handleOpenDeliverablesReviewModal(record)}
                  type="primary"
                >
                  Upload Deliverables For Review
                </Button>
              ) : null}

              {showViewButton && !isLiveAppearance ? (
                <Button
                  key={`deliverables-${record.userId}`}
                  onClick={() => handleOpenDeliverablesSubmissionModal(record)}
                  type="primary"
                >
                  Submit Deliverables Modal
                </Button>
              ) : null}

              {allowSubmitDeliverables && (
                <Button
                  key={`submitDeliverables-${record.userId}`}
                  onClick={() => handleOpenDeliverablesSubmissionModal(record)}
                  type="primary"
                >
                  Submit
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    [
      handleCompleteCampaignOffer,
      handleOpenRevisionHistoryModal,
      handleOpenDeliverablesReviewModal,
      handleOpenDeliverablesSubmissionModal,
    ]
  );

  // don't show the table for bookMe campaigns
  const showTalentTable =
    record?.type === CampaignType.Social ||
    record?.type === CampaignType.SocialShare ||
    record?.type === CampaignType.SponsoredAppearance;

  // don't show the talent selection and adding part for bookMe campaigns or
  // campaigns without fitting status
  const showTalentAdd =
    showTalentTable &&
    (record?.status === CampaignStatus.ON_REVIEW ||
      record?.status === CampaignStatus.DETAIL_FILLED ||
      record?.status === CampaignStatus.PAID_AND_ACTIVE);

  if (!record) {
    return null;
  }

  return (
    <div className={styles.root}>
      {showTalentAdd && (
        <SearchByNameAndEmail record={record} onAdd={refetch} />
      )}

      {showTalentTable && (
        <Table<
          GetCampaignOffers_getCampaignOffers_entities,
          CampaignOffersOrderBy
        >
          columns={columns}
          data={talents}
          scroll={{ x: 300 }}
          loading={loading}
          total={offerData?.getCampaignOffers.total}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          sortInfo={sort}
          setSortInfo={setSortInfo}
        />
      )}

      <CampaignDeliverables
        record={deliverablesSubmissionModalData}
        onClose={handleCloseDeliverablesSubmissionModal}
        onFinish={handleFinish}
      />

      {deliverablesReviewModalData ? (
        <CampaignDeliverablesReview
          offer={deliverablesReviewModalData}
          isRevisionHistoryReadOnly={isRevisionHistoryReadOnly}
          onClose={handleCloseDeliverablesReviewModal}
          onFinish={handleFinish}
        />
      ) : null}
    </div>
  );
};

export default TalentTable;
