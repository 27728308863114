import React from 'react';
import { Form, Button, Input, Typography } from 'antd';
import cn from 'classnames';
// Types
import { errorNotification, successNotification } from 'ui/Notification';
import { StoreValue } from 'antd/lib/form/interface';
// Helpers
import { SocialStateEnum, SocialStateType } from 'helpers/deliverables';
// Components
import UploadMultipleImages, {
  UploadImage,
} from 'ui/UploadMultipleImages/UploadMultipleImages';
// Styles
import styles from './SocialInputField.module.scss';

type SocialInputFieldProps = {
  label: string;
  name: SocialStateEnum;
  isCompletedOrPublished: boolean;
  socialState: SocialStateType;
  placeholder?: string;
  setState: (state: SocialStateType) => void;
};

const { Text } = Typography;

const SocialInputField = ({
  label,
  name,
  isCompletedOrPublished,
  socialState,
  placeholder,
  setState,
}: SocialInputFieldProps): JSX.Element => {
  const normFile = (e: StoreValue) => {
    if (e && Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleSetImages = (incomingData: UploadImage[]) => {
    if (
      name === SocialStateEnum.liveImages ||
      name === SocialStateEnum.storyImages
    ) {
      const newState = { ...socialState };
      newState[name] = [...incomingData];
      setState(newState);
    }
  };

  const handleAddMore = () => {
    const newState = { ...socialState };
    newState[name].push('');
    setState(newState);
  };

  const handleChange = (index: number, value: string) => {
    const newState = { ...socialState };
    newState[name][index] = value;
    setState(newState);
  };

  const handleRemove = (index: number) => {
    if (
      name === SocialStateEnum.iGPosts ||
      name === SocialStateEnum.iGReels ||
      name === SocialStateEnum.tikToks ||
      name === SocialStateEnum.twitterTweets ||
      name === SocialStateEnum.twitterVideos ||
      name === SocialStateEnum.twitterLives
    ) {
      const newState = { ...socialState };

      const newValue = newState[name].filter((_, counter) => index !== counter);
      newState[name] = [...newValue];

      setState(newState);
    }
  };

  const handleCopyToClipboard = (link: string) => {
    try {
      navigator.clipboard.writeText(link);
      successNotification('Link copied to clipboard');
    } catch (e) {
      errorNotification('Failed to copy link to clipboard');
    }
  };

  const withImages =
    name === SocialStateEnum.liveImages || name === SocialStateEnum.storyImages;

  const images =
    name === SocialStateEnum.liveImages
      ? socialState[SocialStateEnum.liveImages]
      : name === SocialStateEnum.storyImages
      ? socialState[SocialStateEnum.storyImages]
      : [];

  const links =
    name === SocialStateEnum.iGPosts
      ? socialState[SocialStateEnum.iGPosts]
      : name === SocialStateEnum.iGReels
      ? socialState[SocialStateEnum.iGReels]
      : name === SocialStateEnum.tikToks
      ? socialState[SocialStateEnum.tikToks]
      : name === SocialStateEnum.twitterTweets
      ? socialState[SocialStateEnum.twitterTweets]
      : name === SocialStateEnum.twitterVideos
      ? socialState[SocialStateEnum.twitterVideos]
      : name === SocialStateEnum.twitterLives
      ? socialState[SocialStateEnum.twitterLives]
      : [];

  return (
    <div className={styles.root}>
      {withImages ? (
        <div className={styles.imageWrapper}>
          <Text className={styles.label}>{label}</Text>

          <Form.Item
            className={styles.imageContainer}
            name={name}
            valuePropName="image"
            getValueFromEvent={normFile}
          >
            {isCompletedOrPublished ? (
              <div className={styles.submittedImages}>
                {images.map((image) => {
                  return (
                    <a
                      className={styles.link}
                      key={image?.data_key}
                      href={image?.data_url || ''}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {image?.data_key}
                    </a>
                  );
                })}
              </div>
            ) : (
              <UploadMultipleImages
                buttonClassName={styles.uploadButtons}
                images={images}
                setImages={handleSetImages}
                isMainImage={null}
              />
            )}
          </Form.Item>
        </div>
      ) : (
        <div
          className={cn(styles.inputWrapper, {
            [styles.linkWrapper]: isCompletedOrPublished,
          })}
        >
          <Text className={styles.label}>{label}</Text>

          {links?.map((link, index) => {
            return (
              <div
                className={cn(styles.inputContainer, {
                  [styles.linkContainer]: isCompletedOrPublished,
                })}
                key={name + '_' + index}
              >
                {isCompletedOrPublished ? (
                  <a
                    className={styles.link}
                    key={link}
                    href={link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {link}
                  </a>
                ) : (
                  <Input
                    placeholder={placeholder}
                    defaultValue={link}
                    onChange={(event) => {
                      handleChange(index, event.target.value);
                    }}
                  />
                )}

                {index !== 0 && !isCompletedOrPublished && (
                  <Button
                    className={styles.removeButton}
                    type="primary"
                    onClick={() => handleRemove(index)}
                  >
                    Remove
                  </Button>
                )}

                {isCompletedOrPublished && (
                  <Button
                    className={styles.addButton}
                    type="primary"
                    onClick={() => handleCopyToClipboard(link)}
                  >
                    Copy
                  </Button>
                )}
              </div>
            );
          })}
          {!isCompletedOrPublished && (
            <Button
              className={styles.addButton}
              type="primary"
              onClick={handleAddMore}
            >
              Add more
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default SocialInputField;
