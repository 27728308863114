import { gql } from '@apollo/client';

export const GET_PLACE_PREDICTIONS = gql`
  query GetPlacePredictions($input: GetPlacePredictionsInput!) {
    getPlacePredictions(input: $input) {
      entities {
        id
        text
        matches {
          startOffset
          endOffset
        }
      }
    }
  }
`;

export const GET_PLACE_DETAILS = gql`
  query GetPlaceDetails($input: GetPlaceDetailsInput!) {
    getPlaceDetails(input: $input) {
      id
      formattedAddress
      location {
        latitude
        longitude
      }
      country
      countryCode
      state
      stateCode
      city
    }
  }
`;
