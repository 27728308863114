import React, { useState, useRef } from 'react';
import { AutoComplete, Input, Typography } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { useApolloClient } from '@apollo/client';
import { GET_PLACE_PREDICTIONS, GET_PLACE_DETAILS } from 'api/places/queries';
import {
  GetPlaceDetails,
  GetPlaceDetails_getPlaceDetails,
  GetPlaceDetailsVariables,
} from 'api/places/types/GetPlaceDetails';
import {
  GetPlacePredictions,
  GetPlacePredictions_getPlacePredictions_entities,
  GetPlacePredictionsVariables,
} from 'api/places/types/GetPlacePredictions';
import { uniqueId } from 'lodash';

export interface PlaceInputProps {
  /** The current value of the input (usually controlled by antd) */
  value?: string;
  /** Label to display above the input */
  label?: string;
  /** Placeholder text for the input */
  placeholder?: string;
  /** Disables the input when true */
  disabled?: boolean;
  /** Error message to display */
  error?: string;
  /**
   * Called when the input value changes
   * (e.g. user types a new value or a suggestion is selected)
   */
  onChange?: (value: string) => void;
  /**
   * Called when a place is selected (after fetching full details).
   * The returned value is the detailed place information.
   */
  onPlaceSelect?: (placeDetails: GetPlaceDetails_getPlaceDetails) => void;
}

const PlaceInput: React.FC<PlaceInputProps> = ({
  value = '',
  label,
  placeholder = 'Enter location...',
  disabled = false,
  error,
  onChange,
  onPlaceSelect,
}) => {
  const [options, setOptions] = useState<
    Array<{
      value: string;
      key: string;
      entity: GetPlacePredictions_getPlacePredictions_entities;
    }>
  >([]);
  // Using a session token to group requests
  const sessionTokenRef = useRef<string>(uniqueId());

  const client = useApolloClient();

  // Debounced function to fetch place predictions when the user types
  const debouncedInputChange = useDebouncedCallback(async (query: string) => {
    if (!query.trim()) {
      setOptions([]);
      return;
    }
    try {
      const { data } = await client.query<
        GetPlacePredictions,
        GetPlacePredictionsVariables
      >({
        query: GET_PLACE_PREDICTIONS,
        variables: {
          input: {
            query,
            sessionToken: sessionTokenRef.current,
          },
        },
        fetchPolicy: 'network-only',
      });
      const entities = data?.getPlacePredictions?.entities || [];
      const newOptions = entities.map((entity) => ({
        value: entity.text, // display text in the dropdown
        key: entity.id,
        entity,
      }));
      setOptions(newOptions);
    } catch (err) {
      console.error('Error fetching predictions:', err);
    }
  }, 1000);

  const handleSearch = (searchText: string) => {
    if (onChange) onChange(searchText);
    debouncedInputChange(searchText);
  };

  // Called when a suggestion is selected.
  const handleSelect = async (selectedValue: string, option: any) => {
    // option.entity is the original entity we attached
    const entity: GetPlacePredictions_getPlacePredictions_entities =
      option.entity;
    if (entity) {
      try {
        const { data } = await client.query<
          GetPlaceDetails,
          GetPlaceDetailsVariables
        >({
          query: GET_PLACE_DETAILS,
          variables: {
            input: {
              id: entity.id,
              sessionToken: sessionTokenRef.current,
            },
          },
          fetchPolicy: 'network-only',
        });
        const placeDetails = data?.getPlaceDetails;
        if (placeDetails && onPlaceSelect) {
          onPlaceSelect(placeDetails);
        }
      } catch (err) {
        console.error('Error fetching place details:', err);
      }
    }
    // Update the input value with the selected suggestion
    if (onChange) onChange(selectedValue);
    sessionTokenRef.current = uniqueId();
  };

  return (
    <div>
      {label && <Typography.Text strong>{label}</Typography.Text>}
      <AutoComplete
        value={value}
        onSearch={handleSearch}
        onSelect={handleSelect}
        options={options}
        disabled={disabled}
        placeholder={placeholder}
        style={{ width: '100%' }}
      >
        <Input />
      </AutoComplete>
      {error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

export default PlaceInput;
