import React, { useState } from 'react';
import { Modal, Button } from 'antd';
// Types
import {
  GetCampaignOffers_getCampaignOffers_entities,
  GetCampaignOffers_getCampaignOffers_entities_deliverables,
} from 'api/campaignV2/types/GetCampaignOffers';
import {
  CampaignOfferStatus,
  CBDeliverableStatus,
} from 'api/graphql-global-types';
// Components
import CampaignDeliverablesSubmitForReviewForm from './CampaignDeliverablesSubmitForReviewForm/CampaignDeliverablesSubmitForReviewForm';
import CampaignDeliverablesSubmissionReview from './CampaignDeliverablesSubmissionReview/CampaignDeliverablesSubmissionReview';
import CampaignDeliverablesRevisionHistory from './CampaignDeliverablesRevisionHistory/CampaignDeliverablesRevisionHistory';
// Styles
import styles from './CampaignDeliverablesReview.module.scss';

type CampaignDeliverablesReviewProps = {
  isRevisionHistoryReadOnly: boolean;
  offer: GetCampaignOffers_getCampaignOffers_entities;
  onClose: () => void;
  onFinish: () => void;
};

const CampaignDeliverablesReview = ({
  isRevisionHistoryReadOnly,
  offer,
  onClose,
  onFinish,
}: CampaignDeliverablesReviewProps): JSX.Element => {
  const [showHistory, setShowHistory] = useState<boolean>(
    isRevisionHistoryReadOnly
  );

  const deliverables = offer.deliverables;

  // check if any deliverable is in ON_REVIEW status
  // it influences if we render a child component and pass data to it
  const reviewDeliverable:
    | GetCampaignOffers_getCampaignOffers_entities_deliverables
    | undefined = deliverables?.find(
    (item) => item.status === CBDeliverableStatus.ON_REVIEW
  );

  const isLimitReached = offer.deliverables?.length === 3;

  const showNewDeliverableForReviewForm: boolean =
    offer.statusV2 === CampaignOfferStatus.ACCEPTED ||
    (offer.statusV2 === CampaignOfferStatus.DELIVERABLES_REJECTED &&
      !isLimitReached);

  const hasDeliverables = !!deliverables?.length && deliverables.length > 0;

  // don't show this button if there is no history of if it's history-read-only
  const showToggleHistoryButton: boolean =
    hasDeliverables && !isRevisionHistoryReadOnly;

  const handleToggleHistory = () => {
    setShowHistory((prevState) => !prevState);
  };

  const handleClose = () => {
    onClose();
    setShowHistory(false);
  };

  return (
    <Modal
      title={isRevisionHistoryReadOnly ? 'Review History' : 'Upload and Review'}
      visible={!!offer}
      footer={null}
      onCancel={handleClose}
      width="850px"
    >
      {showToggleHistoryButton && (
        <div className={styles.buttonWrapper}>
          <Button onClick={handleToggleHistory}>
            {offer && showHistory ? 'Hide history' : 'Show history'}
          </Button>
        </div>
      )}
      {deliverables?.length && showHistory ? (
        <CampaignDeliverablesRevisionHistory deliverables={deliverables} />
      ) : null}
      {reviewDeliverable && (
        <CampaignDeliverablesSubmissionReview
          deliverable={reviewDeliverable}
          offerId={offer.id || ''}
          ownerId={offer.campaign.agency.id || ''}
          onFinish={onFinish}
        />
      )}
      {showNewDeliverableForReviewForm && (
        <CampaignDeliverablesSubmitForReviewForm
          offer={offer}
          onFinish={onFinish}
        />
      )}
    </Modal>
  );
};

export default CampaignDeliverablesReview;
