// Types
import { CreateCampaignDeliverableFiles_createCampaignDeliverableFiles } from 'api/campaignV2/types/CreateCampaignDeliverableFiles';
import { CampaignOfferStatus, CampaignStatus } from 'api/graphql-global-types';
import { SettingsFormInputs } from 'components/common/SetupDeals/SetupDeals';

export const mapCampaignStatusToLabel = (status: CampaignStatus): string => {
  switch (status) {
    case CampaignStatus.DETAIL_FILLED:
      return 'Details filled';
    case CampaignStatus.ON_REVIEW:
      return 'On review';
    case CampaignStatus.DECLINED:
      return 'Declined';
    case CampaignStatus.COMPLETED:
      return 'Completed';
    case CampaignStatus.PAID_AND_ACTIVE:
      return 'Paid and active';
    case CampaignStatus.PAID_AND_BUDGET_FULFILLED:
      return 'Paid and budget fulfilled';
    default:
      return 'N/a';
  }
};

const excludedKeys = [
  'contactEmail',
  'contactPhoneNumberE164',
  'minFlatPrice',
  'minBookMePrice',
  'minSocialPrice',
  'minSponsoredLivePrice',
  'isArticlesPrEnabled',
  'isAcceptingFreeProducts',
];

export const getDisabledCategories = (values: SettingsFormInputs): string[] => {
  return (Object.keys(values) as (keyof SettingsFormInputs)[]).filter(
    (key) => !excludedKeys.includes(key) && values[key] === false
  ) as string[];
};

export const CAMPAIGN_OFFER_STATUS_OPTIONS = [
  {
    text: 'Offer Declined',
    value: CampaignOfferStatus.DECLINED,
  },
  {
    text: 'Offer Sent',
    value: CampaignOfferStatus.PROPOSED,
  },
  {
    text: 'Invited',
    value: CampaignOfferStatus.INVITED,
  },
  {
    text: 'Submitted',
    value: CampaignOfferStatus.SUBMITTED,
  },
  {
    text: 'Published',
    value: CampaignOfferStatus.PUBLISHED,
  },
  {
    text: 'Completed',
    value: CampaignOfferStatus.COMPLETED,
  },
  {
    text: 'Approved',
    value: CampaignOfferStatus.ACCEPTED,
  },
  {
    text: 'Applied',
    value: CampaignOfferStatus.APPLIED,
  },
  {
    text: 'Expired',
    value: CampaignOfferStatus.EXPIRED,
  },
  {
    text: 'Scheduled',
    value: CampaignOfferStatus.SCHEDULED,
  },
  {
    text: 'Deliverables rejected',
    value: CampaignOfferStatus.DELIVERABLES_REJECTED,
  },
  {
    text: 'Deliverables approved',
    value: CampaignOfferStatus.DELIVERABLES_APPROVED,
  },
];

export const SOCIAL_FOLLOWERS_OPTIONS = [
  {
    text: '<10k',
    value: '<10k',
  },
  {
    text: '10k - 100k',
    value: '10k-100k',
  },
  {
    text: '100k - 1m',
    value: '100k-1m',
  },
  {
    text: '1m+',
    value: '1m+',
  },
];

export const mapOfferStatusToLabel = (status: CampaignOfferStatus): string => {
  switch (status) {
    case CampaignOfferStatus.DECLINED:
      return 'Offer Declined';
    case CampaignOfferStatus.PROPOSED:
      return 'Offer Sent';
    case CampaignOfferStatus.INVITED:
      return 'Invited';
    case CampaignOfferStatus.SUBMITTED:
      return 'Submitted';
    case CampaignOfferStatus.ACCEPTED:
      return 'Approved';
    case CampaignOfferStatus.APPLIED:
      return 'Applied';
    case CampaignOfferStatus.EXPIRED:
      return 'Expired';
    case CampaignOfferStatus.SCHEDULED:
      return 'Scheduled';
    case CampaignOfferStatus.PUBLISHED:
      return 'Published';
    case CampaignOfferStatus.DELIVERABLES_APPROVED:
      return 'Deliverables Approved';
    case CampaignOfferStatus.DELIVERABLES_REJECTED:
      return 'Deliverables Declined';
    default:
      return status;
  }
};

interface FollowerRange {
  from: number;
  to: number;
}

const FOLLOWERS_FILTER_RANGES: Record<string, FollowerRange> = {
  '<10k': { from: 0, to: 9_999 },
  '10k-100k': { from: 10_000, to: 100_000 },
  '100k-1m': { from: 100_001, to: 1_000_000 },
  '1m+': { from: 1_000_000, to: 100_000_000 },
};

export const getFollowerRange = (
  selectedFilters: string
): { from: number; to: number } | null => {
  if (!selectedFilters?.length) return null;

  let from = Infinity;
  let to = 0;

  const { from: filterMin, to: filterMax } =
    FOLLOWERS_FILTER_RANGES[selectedFilters];

  from = Math.min(from, filterMin);
  to = Math.max(to, filterMax);

  return { from, to };
};

export type UploadFileType = File & {
  preview?: string;
  path?: string;
};

type FileToUpload = {
  fields: string;
  url: string;
  key: string;
  type: string;
  file: File;
  name: string;
};

export const uploadDeliverables = async (
  preSignedUrls: CreateCampaignDeliverableFiles_createCampaignDeliverableFiles[],
  files: FileWithPreview[]
): Promise<FileToUpload[]> => {
  const res: FileToUpload[] = [];

  files.forEach((file, index) => {
    const preSignedUrl = preSignedUrls?.[index];

    res.push({
      fields: preSignedUrl?.fields || '',
      url: preSignedUrl?.url || '',
      name: file.name,
      type: file.type,
      file: file,
      key: preSignedUrl?.key,
    });
  });

  for (const item of res) {
    const { fields, url, file, key, type, name } = item;
    const formData = new FormData();

    Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    formData.append('key', key);
    formData.append('file', file, name);
    formData.append('Content-Type', type);

    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      console.error('Upload failed:', await response.text());
      throw new Error(`Upload failed for file ${name}`);
    }
  }
  return res;
};
