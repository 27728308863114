/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountSubType {
  Association = "Association",
  Athlete = "Athlete",
  Brand = "Brand",
  Business = "Business",
  ContentCreator = "ContentCreator",
  EventOrganizer = "EventOrganizer",
  FanPage = "FanPage",
  Gym = "Gym",
  Host = "Host",
  League = "League",
  PodcastHost = "PodcastHost",
  Shop = "Shop",
  SocialPage = "SocialPage",
  Team = "Team",
}

export enum AccountType {
  Basic = "Basic",
  Creator = "Creator",
}

export enum AdminOrderBy {
  createdAt = "createdAt",
  firstName = "firstName",
  lastName = "lastName",
}

export enum AdminStoresOrderBy {
  activatedAt = "activatedAt",
  amaVideo = "amaVideo",
  contactRole = "contactRole",
  createdAt = "createdAt",
  email = "email",
  facebookLink = "facebookLink",
  firstName = "firstName",
  followerCount = "followerCount",
  initialAssessment = "initialAssessment",
  instagramLink = "instagramLink",
  lastName = "lastName",
  latestAssessment = "latestAssessment",
  managedByName = "managedByName",
  phoneNumber = "phoneNumber",
  revenue = "revenue",
  socialFollowerCount = "socialFollowerCount",
  socialMediaLink = "socialMediaLink",
  status = "status",
  storeName = "storeName",
  tiktokLink = "tiktokLink",
  totalSales = "totalSales",
  twitterLink = "twitterLink",
}

export enum Age {
  Adult = "Adult",
  Youth = "Youth",
}

export enum AgencyListOrderBy {
  createdAt = "createdAt",
  firstName = "firstName",
  lastName = "lastName",
}

export enum AmaOrderBy {
  CreatedAt = "CreatedAt",
  Price = "Price",
  StoreName = "StoreName",
}

export enum AmaOrderStatus {
  Accepted = "Accepted",
  Completed = "Completed",
  Declined = "Declined",
  Pending = "Pending",
  Processing = "Processing",
}

export enum AmaRequestOrderBy {
  CreatedAt = "CreatedAt",
  CustomerEmail = "CustomerEmail",
  CustomerFirstName = "CustomerFirstName",
  DateToResponse = "DateToResponse",
  DiscountAmount = "DiscountAmount",
  Id = "Id",
  Instructions = "Instructions",
  OrderStatus = "OrderStatus",
  Price = "Price",
  ReceiverEmail = "ReceiverEmail",
}

export enum AmaRequestOrderByFilter {
  amaId = "amaId",
  createdAt = "createdAt",
  email = "email",
  firstName = "firstName",
  lastName = "lastName",
  name = "name",
  orderStatus = "orderStatus",
}

export enum AmaSalesOrderBy {
  createdAt = "createdAt",
  customerEmail = "customerEmail",
  customerName = "customerName",
  discount = "discount",
  earnings = "earnings",
}

export enum AmaStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum AmaType {
  Ama = "Ama",
  Shoutout = "Shoutout",
}

/**
 * price sorts by Order.subtotal
 */
export enum AnyProductSalesOrderBy {
  createdAt = "createdAt",
  customerEmail = "customerEmail",
  customerName = "customerName",
  rawCreatedAt = "rawCreatedAt",
  status = "status",
  subtotal = "subtotal",
  totalStoreRevenues = "totalStoreRevenues",
  type = "type",
}

export enum ArticleOrderBy {
  createdAt = "createdAt",
  status = "status",
  storeName = "storeName",
}

export enum ArticleStatus {
  Active = "Active",
  Disabled = "Disabled",
}

export enum ArticleTypes {
  Sponsored = "Sponsored",
}

export enum AuctionStatus {
  Finished = "Finished",
  Live = "Live",
  Scheduled = "Scheduled",
}

export enum AuditLogType {
  Interview = "Interview",
  VideoEditRequest = "VideoEditRequest",
}

export enum AutoSocialMediaPackStatus {
  failed = "failed",
  generating = "generating",
  missing = "missing",
  ready = "ready",
}

export enum AutoSocialPackImageStatus {
  completed = "completed",
  failed = "failed",
  inProgress = "inProgress",
}

export enum BannerMediaType {
  image = "image",
  video = "video",
}

export enum BusinessType {
  Company = "Company",
  Government_entity = "Government_entity",
  Individual = "Individual",
  Non_profit = "Non_profit",
}

export enum CBDeliverableStatus {
  APPROVED = "APPROVED",
  ON_REVIEW = "ON_REVIEW",
  REJECTED = "REJECTED",
}

export enum CampaignActivityCoveredExpense {
  Flight = "Flight",
  Food = "Food",
  GroundTransportation = "GroundTransportation",
  Hotel = "Hotel",
}

export enum CampaignActivityTargetType {
  SponsoredGuest = "SponsoredGuest",
  SponsoredProduct = "SponsoredProduct",
}

export enum CampaignActivityType {
  Custom = "Custom",
  EventMarketing = "EventMarketing",
  InPersonAppearance = "InPersonAppearance",
  Social = "Social",
  SocialShare = "SocialShare",
  SponsoredAppearance = "SponsoredAppearance",
  VirtualAppearance = "VirtualAppearance",
}

export enum CampaignMediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum CampaignOfferGenderFilter {
  NA = "NA",
  female = "female",
  male = "male",
  other = "other",
  preferNotToSay = "preferNotToSay",
}

/**
 * V2
 * PROPOSED - Offers are created but not sent, campaign is not yet paid
 * INVITED - Offer is sent to talent
 * APPLIED - Talent applied for campaign (offer)
 * ACCEPTED - Agency accepts (approves) talent's application
 * SCHEDULED - SponsoredAppearance: Talent created stream for Live Appearance
 * DECLINED - Agency declines offer application
 * EXPIRED - Offer gets expired after application deadline
 * SUBMITTED - Talent submitted artifact for Brand to review
 * DELIVERABLES_REJECTED - Brand rejected
 * DELIVERABLES_APPROVED - Talent can publish approved deliverables 
 * PUBLISHED - Talent published artifact
 * COMPLETED - Brand approves published artifacts by marking them COMPLETED
 * 
 * V1
 * PROPOSED - Offers are created but not sent, campaign is not yet paid
 * INVITED - Offer is sent to talent
 * APPLIED - Talent applied for campaign (offer)
 * ACCEPTED - Agency accepts (approves) talent's application
 * SCHEDULED - SponsoredAppearance: Talent created stream for Live Appearance
 * DECLINED - Agency declines offer application
 * EXPIRED - Offer gets expired after application deadline
 * SUBMITTED - Talent published artifacts
 */
export enum CampaignOfferStatus {
  ACCEPTED = "ACCEPTED",
  APPLIED = "APPLIED",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  DELIVERABLES_APPROVED = "DELIVERABLES_APPROVED",
  DELIVERABLES_REJECTED = "DELIVERABLES_REJECTED",
  EXPIRED = "EXPIRED",
  INVITED = "INVITED",
  PROPOSED = "PROPOSED",
  PUBLISHED = "PUBLISHED",
  SCHEDULED = "SCHEDULED",
  SUBMITTED = "SUBMITTED",
}

export enum CampaignOffersOrderBy {
  appliedAt = "appliedAt",
  approvedAt = "approvedAt",
  avgVote = "avgVote",
  createdAt = "createdAt",
  createdAtPrioritized = "createdAtPrioritized",
  declinedAt = "declinedAt",
  estimatedInfluencerFee = "estimatedInfluencerFee",
  id = "id",
  name = "name",
  socialFollowing = "socialFollowing",
  socialFollowingLive = "socialFollowingLive",
  status = "status",
  submittedAt = "submittedAt",
}

export enum CampaignSearchType {
  BookMe = "BookMe",
  Social = "Social",
  SocialShare = "SocialShare",
  SponsoredAppearance = "SponsoredAppearance",
}

export enum CampaignSmPlatform {
  FB = "FB",
  IG = "IG",
  TT = "TT",
  TW = "TW",
  YT = "YT",
}

export enum CampaignStatus {
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  DETAIL_FILLED = "DETAIL_FILLED",
  ON_REVIEW = "ON_REVIEW",
  PAID_AND_ACTIVE = "PAID_AND_ACTIVE",
  PAID_AND_BUDGET_FULFILLED = "PAID_AND_BUDGET_FULFILLED",
}

export enum CampaignTaskType {
  FB_SHARE = "FB_SHARE",
  IG_LIVE = "IG_LIVE",
  IG_POST = "IG_POST",
  IG_REEL = "IG_REEL",
  IG_SHARE = "IG_SHARE",
  IG_STORY = "IG_STORY",
  LIVE_APPEARANCE = "LIVE_APPEARANCE",
  RECORDED_APPEARANCE = "RECORDED_APPEARANCE",
  TT_SHARE = "TT_SHARE",
  TT_VIDEO = "TT_VIDEO",
  TW_LIVE = "TW_LIVE",
  TW_SHARE = "TW_SHARE",
  TW_TWEET = "TW_TWEET",
  TW_VIDEO = "TW_VIDEO",
  YT_SHARE = "YT_SHARE",
}

export enum CampaignType {
  BookMe = "BookMe",
  EventMarketing = "EventMarketing",
  Social = "Social",
  SocialShare = "SocialShare",
  SponsoredAppearance = "SponsoredAppearance",
}

export enum CampaignsOrderBy {
  budget = "budget",
  createdAt = "createdAt",
  id = "id",
  name = "name",
  status = "status",
  type = "type",
}

export enum ContactType {
  ATHLETE = "ATHLETE",
  BRAND = "BRAND",
}

export enum DesignPriorityType {
  High = "High",
  Regular = "Regular",
}

export enum DesignRejectionType {
  byManager = "byManager",
  byStore = "byStore",
}

export enum DesignRequestStatus {
  Accepted = "Accepted",
  Archived = "Archived",
  AutoAccepted = "AutoAccepted",
  AwaitingApproval = "AwaitingApproval",
  InProgress = "InProgress",
  OnReview = "OnReview",
  Rejected = "Rejected",
  Todo = "Todo",
}

export enum DesignRequestType {
  LogoAudit = "LogoAudit",
  LogoCreation = "LogoCreation",
  MerchCreation = "MerchCreation",
  MerchCreationWithLogo = "MerchCreationWithLogo",
}

export enum DesignRequestsOrderBy {
  assignDate = "assignDate",
  createdAt = "createdAt",
  designRate = "designRate",
  designRequestId = "designRequestId",
  designRequestType = "designRequestType",
  designerName = "designerName",
  designerStatus = "designerStatus",
  dueDate = "dueDate",
  dueDatePriority = "dueDatePriority",
  status = "status",
  storeName = "storeName",
}

export enum DesignerStatus {
  Active = "Active",
  Inactive = "Inactive",
  Pending = "Pending",
}

export enum DesignersOrderBy {
  createdAt = "createdAt",
  designerName = "designerName",
  designerRate = "designerRate",
  designerRequestCnt = "designerRequestCnt",
  designerStatus = "designerStatus",
  firstName = "firstName",
  lastName = "lastName",
}

export enum ExperienceOrderBy {
  createdAt = "createdAt",
  price = "price",
  title = "title",
}

export enum ExperienceRequestStatus {
  Accepted = "Accepted",
  Declined = "Declined",
  Pending = "Pending",
  Purchased = "Purchased",
  Refunded = "Refunded",
}

export enum ExperienceSalesOrderBy {
  CreatedAt = "CreatedAt",
  CustomerEmail = "CustomerEmail",
  CustomerFirstName = "CustomerFirstName",
  Id = "Id",
  PaidAt = "PaidAt",
  RequestedProfit = "RequestedProfit",
  Status = "Status",
  Title = "Title",
}

export enum ExperienceStatus {
  Active = "Active",
  Inactive = "Inactive",
  Sold = "Sold",
}

/**
 * Youth is deprecated
 */
export enum Gender {
  Female = "Female",
  Male = "Male",
  Unisex = "Unisex",
  Youth = "Youth",
}

export enum GenderInputFilter {
  Female = "Female",
  Male = "Male",
  Youth = "Youth",
}

export enum GetGalleryImagesOrderBy {
  createdAt = "createdAt",
  name = "name",
  updatedAt = "updatedAt",
}

export enum GetGalleryVideosOrderBy {
  createdAt = "createdAt",
  name = "name",
  updatedAt = "updatedAt",
}

export enum GetRedirectsOrderBy {
  createdAt = "createdAt",
  from = "from",
  to = "to",
  updatedAt = "updatedAt",
}

export enum HashtagsFilterOrderBy {
  name = "name",
}

/**
 * Invited - Invited Manually bia backoffice
 * AutoInvited - Invited via trigger
 * Completed - completed manually by admin
 * AutoCompleted - stream finished and all records are ready
 * Canceled - cancelled manually by interviewer/admin
 * AutoCancelled - stream was cancelled (ex. Auto cancel if no one showed up) and as a result interview
 * CompleteFailed - stream ended without all records
 */
export enum InterviewAuditLogAction {
  Assigned = "Assigned",
  AutoCancelled = "AutoCancelled",
  AutoCompleted = "AutoCompleted",
  AutoInvited = "AutoInvited",
  Canceled = "Canceled",
  CompleteFailed = "CompleteFailed",
  Completed = "Completed",
  InviteViewed = "InviteViewed",
  Invited = "Invited",
  NoAvailableInterviewerFound = "NoAvailableInterviewerFound",
  Reset = "Reset",
  ScheduleAttempted = "ScheduleAttempted",
  Scheduled = "Scheduled",
  StreamEdited = "StreamEdited",
}

/**
 * Automatic - Event-triggered (ex. expensive Memorabilia was created) or user initiated througth web
 * Manual - Created manually via backoffice or sent via campaign
 */
export enum InterviewCreationType {
  Automatic = "Automatic",
  Manual = "Manual",
}

export enum InterviewSettingType {
  HighValueMemorabiliaTrigger = "HighValueMemorabiliaTrigger",
  HighValueMerchTrigger = "HighValueMerchTrigger",
  PPVParticipantTrigger = "PPVParticipantTrigger",
  UserRequest = "UserRequest",
}

export enum InterviewSourceType {
  BigMoment = "BigMoment",
  Memorabilia = "Memorabilia",
  Merch = "Merch",
  Other = "Other",
  PPVParticipant = "PPVParticipant",
  Paid = "Paid",
  Product = "Product",
  QAMillions = "QAMillions",
  TalentInvite = "TalentInvite",
  UserRequest = "UserRequest",
}

/**
 * Invited - Invite is sent with or without interviewer assigned
 * InviteViewed - Interviewee visited Millions invite link, Interviewer auto-assign happens at this stage
 * ScheduleAttempted - Calendly link was opened
 * Scheduled - Interviewer/Admin setup a stream date
 * Completed - Stream has successfully ended AND all records are ready
 * Canceled - Either manually cancelled or automatically (nobody attended stream)
 */
export enum InterviewStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  InviteViewed = "InviteViewed",
  Invited = "Invited",
  ScheduleAttempted = "ScheduleAttempted",
  Scheduled = "Scheduled",
}

export enum InterviewerTasksOrderBy {
  createdAt = "createdAt",
  intervieweeName = "intervieweeName",
  scheduledAt = "scheduledAt",
}

export enum MediaJobStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
}

export enum MediaPostStatus {
  published = "published",
  scheduled = "scheduled",
  scheduledInstant = "scheduledInstant",
}

export enum MediaPostsOrderBy {
  publishedAt = "publishedAt",
}

export enum MemorabiliaFulfillment {
  Athlete = "Athlete",
  Millions = "Millions",
}

export enum MemorabiliaOrderBy {
  createdAt = "createdAt",
  inappropriateImageCount = "inappropriateImageCount",
  price = "price",
  title = "title",
}

export enum MemorabiliaProductType {
  Memorabilia = "Memorabilia",
  Product = "Product",
}

export enum MemorabiliaSaleMethod {
  auction = "auction",
  standard = "standard",
}

export enum MemorabiliaStatus {
  Active = "Active",
  Expired = "Expired",
  Removed = "Removed",
  Sold = "Sold",
}

export enum MentionType {
  ARTICLE = "ARTICLE",
  EDITED_VIDEO = "EDITED_VIDEO",
  MEDIA_POST = "MEDIA_POST",
  STREAM = "STREAM",
  VIDEO_REQUEST = "VIDEO_REQUEST",
}

/**
 * Used as a generic status for SubOrder
 */
export enum MerchOrderStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  Pending = "Pending",
  Processing = "Processing",
}

export enum MerchProductOrderBy {
  Date = "Date",
  Price = "Price",
  StoreName = "StoreName",
  Title = "Title",
}

export enum MerchProductStatus {
  Active = "Active",
  Inactive = "Inactive",
  MockupsFailed = "MockupsFailed",
  PendingMockups = "PendingMockups",
}

export enum MerchProductType {
  Hat = "Hat",
  Joggers = "Joggers",
  ManHoodie = "ManHoodie",
  ManJoggers = "ManJoggers",
  ManRashGuard = "ManRashGuard",
  ManTShirt = "ManTShirt",
  ManTankTop = "ManTankTop",
  WomanHoodie = "WomanHoodie",
  WomanJoggers = "WomanJoggers",
  WomanRashGuard = "WomanRashGuard",
  WomanTShirt = "WomanTShirt",
  WomanTankTop = "WomanTankTop",
  YouthTShirt = "YouthTShirt",
}

export enum MerchType {
  Hat = "Hat",
  Hoodie = "Hoodie",
  Joggers = "Joggers",
  RashGuard = "RashGuard",
  TShirt = "TShirt",
}

export enum ModeratorsOrderBy {
  createdAt = "createdAt",
  firstName = "firstName",
  lastName = "lastName",
  moderatorName = "moderatorName",
}

export enum MyTipsAndOrdersSalesOrderBy {
  CustomerEmail = "CustomerEmail",
  CustomerFirstName = "CustomerFirstName",
  Discount = "Discount",
  Earnings = "Earnings",
  PaidAt = "PaidAt",
  Type = "Type",
}

export enum OrganizationType {
  AssociationLeagueTeam = "AssociationLeagueTeam",
  EventOrganizer = "EventOrganizer",
  Gym = "Gym",
  Other = "Other",
}

export enum PlacementTypes {
  back = "back",
  embroidery_back = "embroidery_back",
  embroidery_front = "embroidery_front",
  embroidery_front_large = "embroidery_front_large",
  embroidery_left = "embroidery_left",
  embroidery_right = "embroidery_right",
  front = "front",
  leg_back_left = "leg_back_left",
  leg_back_right = "leg_back_right",
  leg_front_left = "leg_front_left",
  leg_front_right = "leg_front_right",
  pocket = "pocket",
  sleeve_left = "sleeve_left",
  sleeve_right = "sleeve_right",
}

export enum ProductSalesType {
  memorabilia = "memorabilia",
  merch = "merch",
  product = "product",
}

export enum ProductTypesType {
  Ama = "Ama",
  Experience = "Experience",
  Merch = "Merch",
  Product = "Product",
  Shoutout = "Shoutout",
  Stream = "Stream",
}

export enum PromoCodesChargeSubject {
  Internal = "Internal",
  Platform = "Platform",
  Store = "Store",
}

export enum PromoCodesOrderBy {
  amount = "amount",
  chargeSubject = "chargeSubject",
  createdAt = "createdAt",
  endDate = "endDate",
  name = "name",
  startDate = "startDate",
}

export enum PromocodesOrderBy {
  createdAt = "createdAt",
  endDate = "endDate",
  name = "name",
  startDate = "startDate",
}

export enum PsychosocialKeywordOrderBy {
  name = "name",
}

export enum PublicStreamCategory {
  Interview = "Interview",
  Stream = "Stream",
}

export enum ScheduleEventStatus {
  Cancelled = "Cancelled",
  Enabled = "Enabled",
}

export enum ScheduleRuleType {
  Date = "Date",
  WeekDay = "WeekDay",
}

export enum ScheduleType {
  Interview = "Interview",
}

export enum SearchCampaignStoresOrderBy {
  fullName = "fullName",
  profileName = "profileName",
  totalFollowing = "totalFollowing",
}

export enum SignUpFlow {
  QuickFlow = "QuickFlow",
  RegularFlow = "RegularFlow",
}

export enum SocialMediaType {
  Facebook = "Facebook",
  Instagram = "Instagram",
  Tiktok = "Tiktok",
  Twitter = "Twitter",
  Youtube = "Youtube",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SponsoredAppearanceVideoType {
  Live = "Live",
  Recorded = "Recorded",
}

export enum StoreAthleticLevel {
  Amateur = "Amateur",
  CollegeUniversity = "CollegeUniversity",
  OlympicNational = "OlympicNational",
  Other = "Other",
  Professional = "Professional",
  RetiredProfessional = "RetiredProfessional",
}

export enum StoreCareerStatus {
  Active = "Active",
  Retired = "Retired",
}

export enum StoreGender {
  female = "female",
  male = "male",
  other = "other",
  preferNotToSay = "preferNotToSay",
}

export enum StoreInterviewerOrderBy {
  createdAt = "createdAt",
  firstName = "firstName",
  lastName = "lastName",
}

export enum StoreStatus {
  Active = "Active",
  Inactive = "Inactive",
  Pending = "Pending",
  Rejected = "Rejected",
}

export enum StoresOrderBy {
  activatedAt = "activatedAt",
  createdAt = "createdAt",
  firstName = "firstName",
  lastName = "lastName",
  socialFollowerCount = "socialFollowerCount",
  storeName = "storeName",
  totalSales = "totalSales",
}

export enum StreamClipStatus {
  Complete = "Complete",
  Error = "Error",
  Processing = "Processing",
}

export enum StreamIntervalEnum {
  biweekly = "biweekly",
  monthly = "monthly",
  weekly = "weekly",
}

export enum StreamOrderBy {
  Description = "Description",
  Name = "Name",
  Price = "Price",
  RequestedPrice = "RequestedPrice",
  ScheduleDate = "ScheduleDate",
  ScheduleDateStrict = "ScheduleDateStrict",
  StoreName = "StoreName",
  StreamStatus = "StreamStatus",
}

export enum StreamOrderByFilter {
  createdAt = "createdAt",
  email = "email",
  name = "name",
  orderStatus = "orderStatus",
  price = "price",
  scheduleDate = "scheduleDate",
  streamStatus = "streamStatus",
}

export enum StreamOrderSalesOrderBy {
  CreatedAt = "CreatedAt",
  CustomerFirstName = "CustomerFirstName",
  DiscountAmount = "DiscountAmount",
  PaidAt = "PaidAt",
  RequestedProfit = "RequestedProfit",
}

export enum StreamOrderStatus {
  Cancelled = "Cancelled",
  Completed = "Completed",
  Pending = "Pending",
  Processing = "Processing",
}

export enum StreamPriceCategory {
  Free = "Free",
  Paid = "Paid",
}

export enum StreamSalesOrderBy {
  Earnings = "Earnings",
  Name = "Name",
  OrderCnt = "OrderCnt",
  ScheduleDate = "ScheduleDate",
  TipCnt = "TipCnt",
}

export enum StreamStatus {
  Active = "Active",
  Cancelled = "Cancelled",
  Ended = "Ended",
  Interrupted = "Interrupted",
  Paused = "Paused",
  Scheduled = "Scheduled",
}

export enum StreamSubtypeFilter {
  GuestInterview = "GuestInterview",
  HostInterview = "HostInterview",
  Regular = "Regular",
  SponsoredAppearance = "SponsoredAppearance",
}

export enum TargetType {
  REGISTERED = "REGISTERED",
  UNREGISTERED = "UNREGISTERED",
}

export enum UnmergedMerchOrderBy {
  createdAt = "createdAt",
  title = "title",
  type = "type",
}

export enum UnregisteredContactOrderBy {
  createdAt = "createdAt",
  name = "name",
  timesMentioned = "timesMentioned",
}

export enum UserAreaOfInfluenceOrderBy {
  name = "name",
}

/**
 * Gym is deprecated and substituted with Organization
 */
export enum UserRole {
  Admin = "Admin",
  Agency = "Agency",
  Association = "Association",
  Athlete = "Athlete",
  Charity = "Charity",
  Coach = "Coach",
  ContentCreator = "ContentCreator",
  Customer = "Customer",
  Designer = "Designer",
  Guest = "Guest",
  Gym = "Gym",
  ManagementFirm = "ManagementFirm",
  Moderator = "Moderator",
  Organization = "Organization",
  Team = "Team",
  Trainer = "Trainer",
  VideoEditor = "VideoEditor",
}

export enum UsersOrderFields {
  createdAt = "createdAt",
  email = "email",
  facebookLink = "facebookLink",
  firstName = "firstName",
  instagramLink = "instagramLink",
  lastName = "lastName",
  phoneNumber = "phoneNumber",
  role = "role",
  slug = "slug",
  socialMediaLink = "socialMediaLink",
  status = "status",
  tiktokLink = "tiktokLink",
  twitterLink = "twitterLink",
}

export enum VideoEditRequestAuditLogAction {
  Approve = "Approve",
  Archive = "Archive",
  Assign = "Assign",
  AutoCreated = "AutoCreated",
  CreatedAndAssigned = "CreatedAndAssigned",
  Reassign = "Reassign",
  RejectAndSendBack = "RejectAndSendBack",
  RejectAndSendReassign = "RejectAndSendReassign",
}

export enum VideoEditRequestSettingType {
  InterviewTrigger = "InterviewTrigger",
  MediaLabTrigger = "MediaLabTrigger",
  SponsoredAppearanceTrigger = "SponsoredAppearanceTrigger",
  StreamTrigger = "StreamTrigger",
}

export enum VideoEditRequestSourceType {
  Interview = "Interview",
  MediaPost = "MediaPost",
  SponsoredAppearance = "SponsoredAppearance",
  Stream = "Stream",
}

/**
 * Todo - created from trigger if no editors available
 */
export enum VideoEditRequestStatus {
  Accepted = "Accepted",
  Archived = "Archived",
  AutoAccepted = "AutoAccepted",
  InProgress = "InProgress",
  OnReview = "OnReview",
  PartialyRejected = "PartialyRejected",
  Rejected = "Rejected",
  Todo = "Todo",
}

export enum VideoEditRequestSubmissionStatus {
  Approved = "Approved",
  AutoApproved = "AutoApproved",
  Pending = "Pending",
  Rejected = "Rejected",
}

export enum VideoEditRequestsOrderBy {
  assignedEditor = "assignedEditor",
  createdAt = "createdAt",
  dueDate = "dueDate",
  status = "status",
  storeName = "storeName",
  videoEditRequestType = "videoEditRequestType",
  videoEditorName = "videoEditorName",
}

export enum VideoEditorOrderBy {
  createdAt = "createdAt",
  firstName = "firstName",
  lastName = "lastName",
  videoEditorName = "videoEditorName",
  videoEditorRate = "videoEditorRate",
  videoEditorStatus = "videoEditorStatus",
}

export enum VideoRequestFeedBackType {
  Accepted = "Accepted",
  Reassigned = "Reassigned",
  Rejected = "Rejected",
}

export enum WeekDay {
  friday = "friday",
  monday = "monday",
  saturday = "saturday",
  sunday = "sunday",
  thursday = "thursday",
  tuesday = "tuesday",
  wednesday = "wednesday",
}

export interface AcceptMerchDesignInput {
  designRequestId: string;
  feedback?: string | null;
  rate?: number | null;
}

export interface AcceptVideoEditRequestInput {
  feedback?: string | null;
  rate: number;
  videoEditRequestId: string;
  videoEditorId?: string | null;
}

export interface AddConnectedAccountWithLinkInput {
  businessType: BusinessType;
  country?: string | null;
  refreshUrl?: string | null;
  returnUrl?: string | null;
  userId: string;
}

export interface AddHashtagInput {
  name: string;
  sportIds?: number[] | null;
}

export interface AddKeywordInput {
  name: string;
}

export interface AddOfferToCampaignInput {
  campaignId: string;
  talentId: string;
}

export interface AddOfflineCampaignPaymentInput {
  amount: number;
  campaignId: string;
  comment?: string | null;
}

export interface AddUserAreaOfInfluenceInput {
  name: string;
}

export interface AdminArchiveMerchDesignInput {
  designRequestId: string;
}

export interface AdminAssignToDesignerInput {
  designRequestId: string;
  designerId: string;
}

export interface AdminCancelStreamOrderInput {
  streamOrderId: string;
}

export interface AdminChangePasswordInput {
  id: string;
  newPassword: string;
}

export interface AdminCreateAmasInput {
  amas: AmaItemCreateInput[];
  storeId: string;
  video?: VideoCroppedInput | null;
  videoV2?: AmaVideoInput | null;
}

export interface AdminCreateGalleryImageInput {
  file: any;
  name?: string | null;
}

export interface AdminCreateGalleryVideoInput {
  file: any;
  name?: string | null;
}

export interface AdminCreateMerchDesignInput {
  designColorPaletteIds: number[];
  designCustomColor?: string | null;
  designExtraInfo?: string | null;
  designName: string;
  designSampleIds: number[];
  designStyleSettingsInput: DesignStyleSettingsInput;
  designTypeIds: number[];
  designerId?: string | null;
  merchProductTypes?: MerchProductType[] | null;
  merchTypes?: MerchType[] | null;
  socialMediaURL?: string | null;
  storeExampleImages?: CreateStoreExampleImageInput[] | null;
  storeId: string;
}

export interface AdminCreatePostImagePresignedUrlV2Input {
  files: CreatePostImagePresignedUrlV2FileInput[];
  storeId: string;
}

export interface AdminDeleteGalleryImageInput {
  id: string;
}

export interface AdminDeleteGalleryVideoInput {
  id: string;
}

export interface AdminEditAmasInput {
  amas?: AmaItemEditInput[] | null;
  deleteVideo?: boolean | null;
  storeId: string;
  video?: VideoCroppedInput | null;
  videoV2?: AmaVideoInput | null;
}

export interface AdminEditInput {
  adminId: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  newPassword?: string | null;
  phoneNumber?: string | null;
}

export interface AdminEditMerchDesignInput {
  designColorPaletteIds?: number[] | null;
  designCustomColor?: string | null;
  designExtraInfo?: string | null;
  designName?: string | null;
  designRequestId: string;
  designSampleIds?: number[] | null;
  designStyleSettingsInput?: DesignStyleSettingsInput | null;
  designTypeIds?: number[] | null;
  merchProductTypes?: MerchProductType[] | null;
  merchTypes?: MerchType[] | null;
  socialMediaPackImages?: SocialMediaPackImageInput[] | null;
  socialMediaURL?: string | null;
  storeExampleImages?: CreateStoreExampleImageInput[] | null;
  storeId: string;
}

export interface AdminEditMerchDesignWithLogoInput {
  designExtraInfo?: string | null;
  designRequestId: string;
  merchProductTypes?: MerchProductType[] | null;
  merchTypes?: MerchType[] | null;
  socialMediaPackImages?: SocialMediaPackImageInput[] | null;
  socialMediaURL?: string | null;
  storeExampleImages?: CreateStoreExampleImageInput[] | null;
  storeId: string;
  storeLogos?: CreateMerchDesignLogoInput[] | null;
}

export interface AdminFilterInput {
  adminIds?: string[] | null;
  direction?: SortDirection | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: AdminOrderBy | null;
  phoneNumber?: number | null;
  status?: StoreStatus[] | null;
}

export interface AdminGetGalleryImagesInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: GetGalleryImagesOrderBy | null;
}

export interface AdminGetGalleryVideosInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: GetGalleryVideosOrderBy | null;
}

export interface AdminInitVideoPostMultipartUploadInput {
  contentType: string;
  fileExt: string;
  partCnt: number;
  storeId: string;
}

export interface AdminSetProductSuborderShippedInput {
  suborderId: string;
}

export interface AdminStoresFilterInput {
  activatedAtDateRange?: FromToDatesFilter | null;
  areaOfInfluenceIds?: string[] | null;
  direction?: SortDirection | null;
  email?: string | null;
  facebookLink?: string | null;
  firstName?: string | null;
  followersRange?: FromToFilter | null;
  hasActiveAma?: boolean | null;
  hasActiveExperiences?: boolean | null;
  hasActiveMerch?: boolean | null;
  hasActiveProducts?: boolean | null;
  hasActiveStreams?: boolean | null;
  hasAma?: boolean | null;
  hasAreaOfInfluence?: boolean | null;
  hasArticle?: boolean | null;
  hasBirthDate?: boolean | null;
  hasExperiences?: boolean | null;
  hasFacebook?: boolean | null;
  hasInstagram?: boolean | null;
  hasMerch?: boolean | null;
  hasOpenDesignRequest?: boolean | null;
  hasProducts?: boolean | null;
  hasPsychosocialKeywords?: boolean | null;
  hasStreams?: boolean | null;
  hasTiktok?: boolean | null;
  hasTwitter?: boolean | null;
  hasWebsite?: boolean | null;
  hasYoutube?: boolean | null;
  hashtagIds?: number[] | null;
  id?: string | null;
  initialAssessmentRange?: AssessmentRangeInput | null;
  instagramLink?: string | null;
  isDeleteRequested?: boolean | null;
  isOnboarded?: boolean | null;
  isShoppingEnabled?: boolean | null;
  isStreamer?: boolean | null;
  latestAssessmentRange?: AssessmentRangeInput | null;
  limit?: number | null;
  managedByName?: string | null;
  offset?: number | null;
  orderBy?: AdminStoresOrderBy | null;
  phoneNumber?: number | null;
  phoneNumberV2?: string | null;
  psychosocialKeywordsIds?: string[] | null;
  revenueRange?: FromToFilter | null;
  searchTerm?: string | null;
  signUpDateRange?: FromToDatesFilter | null;
  slug?: string | null;
  socialFollowersRange?: FromToFilter | null;
  socialMediaLink?: string | null;
  sportId?: number | null;
  sports?: number[] | null;
  status?: StoreStatus[] | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  tiktokLink?: string | null;
  twitterLink?: string | null;
  withOrphans?: boolean | null;
}

export interface AllHashtagsFilterInput {
  direction?: SortDirection | null;
  generalOnly?: boolean | null;
  hashtagNames?: string[] | null;
  keyword?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: HashtagsFilterOrderBy | null;
  sportIds?: number[] | null;
}

export interface AmaFilterInput {
  daysToResponse?: FromToFilter | null;
  direction?: SortDirection | null;
  hashtagIds?: number[] | null;
  id?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: AmaOrderBy | null;
  price?: FromToFilter | null;
  sports?: number[] | null;
  status?: AmaStatus | null;
  storeId?: string | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeStatus?: StoreStatus | null;
}

export interface AmaItemCreateInput {
  daysToResponse: number;
  requestedPrice: number;
  status: AmaStatus;
  type: AmaType;
}

export interface AmaItemEditInput {
  daysToResponse: number;
  id?: string | null;
  requestedPrice: number;
  status: AmaStatus;
  type: AmaType;
}

export interface AmaRequestCreatePresignedUrlInput {
  id: string;
}

export interface AmaRequestDeclineInput {
  declineReason: string;
  id: string;
}

export interface AmaRequestFilterInput {
  customerId?: string | null;
  direction?: SortDirection | null;
  id?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: AmaRequestOrderBy | null;
  orderStatus?: AmaOrderStatus[] | null;
  storeId?: string | null;
}

export interface AmaRequestQueryInput {
  createdAtDateRange?: FromToDatesFilter | null;
  customerId: string;
  direction?: SortDirection | null;
  email?: string | null;
  firstName?: string | null;
  instructions?: string | null;
  lastName?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: AmaRequestOrderByFilter | null;
  orderStatus?: AmaOrderStatus[] | null;
}

export interface AmaRequestSubmitVideoInput {
  id: string;
  videoKey: string;
}

export interface AmaVideoInput {
  area: VideoCroppedArea;
  type?: string | null;
  videoKey: string;
}

export interface AppearanceStreamRecordInput {
  key: string;
}

export interface ApproveCampaignInput {
  id: string;
}

export interface ApproveDeliverableInput {
  deliverableId: string;
  offerId: string;
  ownerId?: string | null;
}

export interface ApproveMerchDesignByManagerInput {
  designRequestId: string;
}

export interface AreaOfInfluenceFilterInput {
  direction?: SortDirection | null;
  keyword?: string | null;
  limit?: number | null;
  names?: string[] | null;
  offset?: number | null;
  orderBy?: UserAreaOfInfluenceOrderBy | null;
}

export interface ArticleFilterInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: ArticleOrderBy | null;
  statuses?: ArticleStatus[] | null;
}

export interface AssessmentRangeInput {
  from?: number | null;
  to?: number | null;
}

export interface AssignInterviewAdminInput {
  id: string;
  interviewerId: string;
}

export interface AthleteStoryInputType {
  description?: string | null;
  id?: string | null;
  image?: any | null;
  link: string;
  orderIndex: number;
  publishedDate: any;
  storeId: string;
  title: string;
}

export interface AttachThankYouVideoInput {
  orderId: string;
  productType: ProductTypesType;
  storeId?: string | null;
  video: any;
}

export interface AttachThankYouVideoStreamInput {
  storeId?: string | null;
  streamId: string;
  video: any;
}

export interface AttachThankYouVideoTemplateInput {
  orderId: string;
  productType: ProductTypesType;
  storeId?: string | null;
  thankYouVideoId: string;
}

export interface AttachThankYouVideoTemplateStreamInput {
  storeId?: string | null;
  streamId: string;
  thankYouVideoId: string;
}

export interface AuctionFilterInput {
  auctionIds?: string[] | null;
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: string | null;
  scheduleDate?: FromToDatesFilter | null;
  status?: AuctionStatus[] | null;
  storeName?: string | null;
  title?: string | null;
}

export interface AuctionFromToInput {
  onAuctionFrom: any;
  onAuctionTo: any;
}

export interface AvailableSlotsDateRangeInput {
  from: any;
  to: any;
}

export interface BannerInputType {
  buttonContent?: string | null;
  buttonLink?: string | null;
  content?: string | null;
  isBannerVisible?: boolean | null;
  link?: string | null;
  linkContent?: string | null;
  mediaId: string;
  mediaType?: BannerMediaType | null;
  orderIndex: number;
  shouldLinkRedirect?: boolean | null;
  title?: string | null;
}

export interface BookInterviewSlotInput {
  guestNotes?: string | null;
  guestTimezone?: string | null;
  interviewId: string;
  startDateUtc: any;
}

export interface BookMeActivityInput {
  custom?: CustomActivityInput | null;
  inPersonAppearance?: InPersonActivityInput | null;
  social?: SocialActivityInput | null;
  type: CampaignActivityType;
  virtualAppearance?: VirtualAppearanceActivityInput | null;
}

export interface CampaignExampleFileInput {
  key: string;
}

export interface CampaignLocationInput {
  city?: string | null;
  country: string;
  state?: string | null;
}

export interface CampaignOfferSubmissionInput {
  imageKey?: string | null;
  link?: string | null;
  type: CampaignTaskType;
}

export interface CancelBidInput {
  bidId: string;
}

export interface CancelInterviewAdminInput {
  id: string;
}

export interface CancelInterviewInput {
  id: string;
}

export interface ChangePasswordInput {
  newPassword: string;
  oldPassword?: string | null;
}

export interface CompleteCampaignInput {
  id: string;
  ownerId?: string | null;
}

export interface CompleteCampaignOfferInput {
  offerId: string;
  ownerId?: string | null;
}

export interface CompleteInterviewAdminInput {
  id: string;
  streamId: string;
}

export interface CompleteMultipartUploadInput {
  fileId: string;
  fileKey: string;
  parts: CompleteMultipartUploadPartInput[];
}

export interface CompleteMultipartUploadPartInput {
  ETag: string;
  PartNumber: number;
}

export interface CreateAddressInput {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  company?: string | null;
  country?: string | null;
  countryCode: string;
  email?: string | null;
  firstName?: string | null;
  isDefault?: boolean | null;
  lastName?: string | null;
  phone?: string | null;
  phoneNumberE164?: string | null;
  state?: string | null;
  stateCode?: string | null;
  userId?: string | null;
  zipCode: string;
}

export interface CreateAdminInput {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
}

export interface CreateAffiliateProductInput {
  imageKey: string;
  price: number;
  title: string;
  url: string;
}

export interface CreateArticleInput {
  articleUrl?: string | null;
  content: string;
  hashtagInputs: HashtagInput[];
  image: CreateImageCoverInput;
  mentionsInputs?: CreateMentionInput[] | null;
  storeIds: string[];
  title: string;
  type: ArticleTypes;
}

export interface CreateArticlePresignedUrlInput {
  contentType: string;
  ext: string;
}

export interface CreateBulkImagePostAdminInput {
  athleteName?: string | null;
  body?: string | null;
  buyProductLink?: string | null;
  description?: string | null;
  email: string;
  hashtagInputs?: HashtagInput[] | null;
  image: CreateBulkPostImageInput;
  mentionsInputs?: CreateMentionInput[] | null;
  originalMediaUrl?: string | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  title: string;
}

export interface CreateBulkPostImageInput {
  key: string;
}

export interface CreateBulkUploadInput {
  images: CreateBulkImagePostAdminInput[];
  videos: CreateBulkVideoPostAdminInput[];
}

export interface CreateBulkVideoPostAdminInput {
  athleteName?: string | null;
  body?: string | null;
  buyProductLink?: string | null;
  description: string;
  email: string;
  hashtagInputs?: HashtagInput[] | null;
  mentionsInputs?: CreateMentionInput[] | null;
  originalMediaUrl?: string | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  thumbnailKey: string;
  title: string;
  video: CreatePostVideoInputBulk;
}

export interface CreateCampaignDetailsInput {
  avoid?: string | null;
  brandName: string;
  dueDate: any;
  encouraged?: string | null;
  exampleFiles?: CampaignExampleFileInput[] | null;
  hashtags?: string[] | null;
  instructions: string;
  linkBio?: boolean | null;
  linkBioDurationDays?: number | null;
  mediaType?: CampaignMediaType[] | null;
  name: string;
  physicalProduct?: boolean | null;
  physicalProductType?: string | null;
  pinnedPost?: boolean | null;
  pinnedPostDurationDays?: number | null;
  productName?: string | null;
  productValue?: number | null;
  profileMentions?: string | null;
  promoCodes?: string[] | null;
  promoUrls?: string[] | null;
  quantityInstructions?: string | null;
  scriptUrl?: string | null;
  suggestedCaption?: string | null;
  tasks: CreateCampaignTaskInput[];
  usageRights?: boolean | null;
  usageRightsText?: string | null;
  website?: string | null;
}

export interface CreateCampaignExampleFileInput {
  name: string;
}

export interface CreateCampaignExampleFilesInput {
  files: CreateCampaignExampleFileInput[];
}

export interface CreateCampaignSubmissionFileInput {
  name: string;
}

export interface CreateCampaignSubmissionFilesInput {
  files: CreateCampaignSubmissionFileInput[];
  offerId: string;
}

export interface CreateCampaignTaskInput {
  type: CampaignTaskType;
}

export interface CreateDeliverableFileInput {
  name: string;
}

export interface CreateDeliverableFilesInput {
  files: CreateDeliverableFileInput[];
}

export interface CreateDesignImageExamplePresignedUrlInput {
  numberOfImages: number;
}

export interface CreateDesignImagePresignedUrl {
  designRequestId: string;
  numberOfImages: number;
}

export interface CreateEditedVideoInput {
  key: string;
}

export interface CreateExperienceImagePresignedUrlInput {
  numberOfImages: number;
}

export interface CreateExperienceInput {
  comment?: string | null;
  description?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  images: ExperienceImageInput[];
  numberOfUnits?: number | null;
  requestedPrice: number;
  storeId: string;
  title: string;
}

export interface CreateImageCoverInput {
  imageLabel: string;
  key: string;
}

export interface CreateImagePostAdminInput {
  body?: string | null;
  buyProductLink?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  image: CreatePostImageInput;
  mentionsInputs?: CreateMentionInput[] | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  storeId: string;
  title: string;
}

export interface CreateMemorabiliaImagePresignedUrl {
  numberOfImages: number;
}

export interface CreateMemorabiliaInput {
  addressId?: string | null;
  auctionDateRange?: AuctionFromToInput | null;
  comment?: string | null;
  customsDescription?: string | null;
  description?: string | null;
  fulfillment: MemorabiliaFulfillment;
  hashtagInputs?: HashtagInput[] | null;
  height?: number | null;
  images: MemorabiliaImageInput[];
  length?: number | null;
  memorabiliaProductType: MemorabiliaProductType;
  numberOfUnits: number;
  requestedPrice: number;
  shipmentCategory: string;
  storeId: string;
  title: string;
  weight?: number | null;
  width?: number | null;
}

export interface CreateMentionInput {
  storeId?: string | null;
  unregisteredContactId?: string | null;
}

export interface CreateMerchDesignLogoInput {
  key: string;
}

export interface CreateMerchImagePresignedUrl {
  numberOfImages: number;
}

export interface CreateMerchProductV2AdminInput {
  customImageSets?: MerchProductCustomImageSetInput[] | null;
  designRequestId?: string | null;
  finalMerchStatus?: MerchProductStatus | null;
  hashtagInputs?: HashtagInput[] | null;
  printFileSets: MerchProductPrintFileSetInput[];
  requestedProfit: number;
  storeId: string;
  title: string;
  type: MerchType;
  variantSets: MerchProductVariantSet[];
}

export interface CreatePastAppearanceStreamInput {
  ownerId?: string | null;
  stream: PastAppearanceStreamInput;
  submissionId: string;
}

export interface CreatePayoutOrderInput {
  amount: number;
  currency: string;
  payouts?: UserPayoutInput[] | null;
  periodFrom: any;
  periodTo: any;
  storeId: string;
}

export interface CreatePostImageInput {
  key: string;
}

export interface CreatePostImagePresignedUrlV2FileInput {
  contentType: string;
  ext: string;
}

export interface CreatePostVideoInput {
  key: string;
}

export interface CreatePostVideoInputBulk {
  key: string;
}

export interface CreatePresignedAffiliateProductImageUrlsInput {
  numberOfImages: number;
}

export interface CreatePromocodeInput {
  amaIds?: string[] | null;
  amount: number;
  chargeSubject: PromoCodesChargeSubject;
  endDate?: any | null;
  endUtc?: any | null;
  isSiteWide?: boolean | null;
  merchProductIds?: string[] | null;
  name: string;
  perUserLimit: number;
  siteWideOptions?: ProductTypesType[] | null;
  startDate?: any | null;
  startUtc?: any | null;
  streamIds?: string[] | null;
  timeZone?: TimeZoneInput | null;
  tz?: string | null;
  usageLimit: number;
}

export interface CreateRedirectInput {
  from: string;
  to: string;
}

export interface CreateStoreExampleImageInput {
  key: string;
}

export interface CreateStreamAdminInput {
  affiliateProducts?: CreateAffiliateProductInput[] | null;
  description: string;
  hashtagInputs?: HashtagInput[] | null;
  image?: any | null;
  imageFileKey?: string | null;
  isFree?: boolean | null;
  isHidden?: boolean | null;
  isPPV?: boolean | null;
  mentionsInputs?: CreateMentionInput[] | null;
  moderatorId?: string | null;
  name?: string | null;
  participantIds?: string[] | null;
  repeatingTitle?: string | null;
  repeatsEveryEnum?: StreamIntervalEnum | null;
  requestedPrice?: number | null;
  scheduleDate?: any | null;
  scheduleDateV2?: DateV2Input | null;
  sponsors?: StreamCreateSponsorsInput[] | null;
  sportIds?: number[] | null;
  storeId: string;
  timeZone?: TimeZoneInput | null;
}

export interface CreateStreamClipInput {
  endTs: number;
  startTs: number;
  streamRecordId: number;
  title?: string | null;
}

export interface CreateStreamImageAdminInput {
  files: StreamImageItemInput[];
  storeId: string;
}

export interface CreateStreamImageInput {
  files: StreamImageItemInput[];
}

export interface CreateThankYouVideoTemplateInput {
  productType: ProductTypesType;
  storeId?: string | null;
  video: any;
}

export interface CreateUnregisteredContactInput {
  email?: string | null;
  name?: string | null;
  phoneNumberE164?: string | null;
  representativeName?: string | null;
  socialMediaLink?: string | null;
  type: ContactType;
  url?: string | null;
}

export interface CreateVariantPrintfilePresignedUrlsInput {
  files: VariantPrintfilePresignedUrlInput[];
}

export interface CreateVideoEditThumbnailImagePresignedUrlV2FileInput {
  contentType: string;
  ext: string;
}

export interface CreateVideoEditThumbnailImagePresignedUrlV2Input {
  files: CreateVideoEditThumbnailImagePresignedUrlV2FileInput[];
}

export interface CreateVideoPostAdminInput {
  body?: string | null;
  buyProductLink?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  mentionsInputs?: CreateMentionInput[] | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  storeId: string;
  thumbnailKey?: string | null;
  title: string;
  video: CreatePostVideoInput;
}

export interface CustomActivityInput {
  files?: CampaignExampleFileInput[] | null;
  instructions: string;
}

export interface DashboardFilter {
  from?: any | null;
  storeId?: string | null;
  to?: any | null;
}

export interface DateV2Input {
  tz?: string | null;
  utc: any;
}

export interface DeclineCampaignInput {
  id: string;
}

export interface DeleteEditedVideoInput {
  editedVideoId: string;
  key?: string | null;
}

export interface DeleteExperienceInput {
  id: string;
}

export interface DeleteInappropriateMarkInput {
  id: string;
}

export interface DeleteMediaPostAdminInput {
  id: string;
  storeId: string;
}

export interface DeleteMemorabiliaInput {
  id: string;
  storeId?: string | null;
}

export interface DeleteRedirectInput {
  id: string;
}

export interface DeleteStreamClipInput {
  id: string;
}

export interface DeleteStreamInput {
  id: string;
}

export interface DeleteThankYouVideoTemplateInput {
  storeId?: string | null;
  thankYouVideoId: string;
}

export interface DeliverableFileInput {
  key: string;
  name: string;
}

export interface DesignMerchChangePriorityInput {
  designPriorityType: DesignPriorityType;
  designRequestId: string;
}

export interface DesignRequestsFilterInput {
  designRequestId?: string | null;
  designRequestIds?: string[] | null;
  designRequestStatuses?: DesignRequestStatus[] | null;
  designRequestType?: DesignRequestType | null;
  designRequestTypes?: DesignRequestType[] | null;
  designerIds?: string[] | null;
  designerName?: string | null;
  direction?: SortDirection | null;
  escalatedToId?: string | null;
  isAwaitingAthleteApproval?: boolean | null;
  isInProgress?: boolean | null;
  isOverdue?: boolean | null;
  isUnassigned?: boolean | null;
  limit?: number | null;
  managedById?: string | null;
  offset?: number | null;
  orderBy?: DesignRequestsOrderBy | null;
  storeIds?: string[] | null;
  storeName?: string | null;
}

export interface DesignStyleSettingsInput {
  abstractLiteral?: number | null;
  classicModern?: number | null;
  economicalLuxurious?: number | null;
  feminineMasculine?: number | null;
  geometricOrganic?: number | null;
  matureYouthful?: number | null;
  playfulSophisticated?: number | null;
}

export interface DesignerCreateInput {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  socialMediaLink?: string | null;
}

export interface DesignerDetailsEditInput {
  isIncludedInAutoAssignment?: boolean | null;
}

export interface DesignerEditInput {
  designerDetails?: DesignerDetailsEditInput | null;
  designerId?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  phoneNumber?: string | null;
  socialMediaLink?: string | null;
}

export interface DesignersFilterInput {
  designRequestStatuses?: DesignRequestStatus[] | null;
  designerIds?: string[] | null;
  designerName?: string | null;
  designerStatus?: DesignerStatus[] | null;
  direction?: SortDirection | null;
  email?: string | null;
  hasActiveDesignRequests?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: DesignersOrderBy | null;
}

export interface DownloadCampaignOffersFullInput {
  campaignId: string;
}

export interface EarthCoordinateInput {
  latitude: number;
  longitude: number;
}

export interface EditAffiliateProductInput {
  id?: string | null;
  imageKey: string;
  price: number;
  title: string;
  url: string;
}

export interface EditEditedVideoInput {
  body?: string | null;
  editedVideoId: string;
  hashtagInputs?: HashtagInput[] | null;
  key?: string | null;
  mentionsInputs?: CreateMentionInput[] | null;
  thumbnailKey?: string | null;
  title?: string | null;
}

export interface EditHashtagInput {
  id: number;
  name?: string | null;
  sportIds?: number[] | null;
}

export interface EditImagePostAdminInput {
  body?: string | null;
  buyProductLink?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  image: EditPostImageInput;
  mentionsInputs?: CreateMentionInput[] | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  storeId: string;
  title: string;
}

export interface EditInterviewerDetailsInput {
  scheduleLink?: string | null;
}

export interface EditKeywordInput {
  id: string;
  name?: string | null;
}

export interface EditMerchProductV2AdminInput {
  customImageSets?: MerchProductCustomImageSetInput[] | null;
  finalMerchStatus?: MerchProductStatus | null;
  hashtagInputs?: HashtagInput[] | null;
  id: number;
  printFileSets?: MerchProductPrintFileSetInput[] | null;
  requestedProfit: number;
  storeId: string;
  title: string;
  type: MerchType;
  variantSets?: MerchProductVariantSet[] | null;
}

export interface EditPostImageInput {
  id?: string | null;
  key?: string | null;
}

/**
 * Provide either id to keep existing video or a key to upload new video
 */
export interface EditPostVideoInput {
  id?: string | null;
  key?: string | null;
}

export interface EditRedirectInput {
  from: string;
  id: string;
  to: string;
}

export interface EditStreamAdminInput {
  affiliateProducts?: EditAffiliateProductInput[] | null;
  description: string;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  image?: any | null;
  imageFileKey?: string | null;
  isFree?: boolean | null;
  isHidden?: boolean | null;
  isPPV?: boolean | null;
  mentionsInputs?: CreateMentionInput[] | null;
  moderatorId?: string | null;
  name?: string | null;
  participantIds?: string[] | null;
  repeatingTitle?: string | null;
  repeatsEveryEnum?: StreamIntervalEnum | null;
  requestedPrice?: number | null;
  scheduleDate?: any | null;
  scheduleDateV2?: DateV2Input | null;
  sponsors?: StreamCreateSponsorsInput[] | null;
  sportIds?: number[] | null;
  storeId: string;
  timeZone?: TimeZoneInput | null;
}

export interface EditUserAreaOfInfluenceInput {
  id: string;
  name?: string | null;
}

export interface EditVideoPostAdminInput {
  body?: string | null;
  buyProductLink?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  mentionsInputs?: CreateMentionInput[] | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  storeId: string;
  thumbnailKey?: string | null;
  title: string;
  video: EditPostVideoInput;
}

export interface EstimateMerchPriceInput {
  printFileSets: MerchEstimatePrintFileSetInput[];
  requestedProfit: number;
  type: MerchType;
  variantSets: MerchProductVariantSet[];
}

export interface EventMarketingActivityInput {
  description: string;
  participantCount: number;
  socialMediaLinks?: string[] | null;
  sport: string;
  startDate: any;
}

export interface EventMarketingCampaignActivityInput {
  eventMarketing?: EventMarketingActivityInput | null;
  type: CampaignActivityType;
}

export interface ExperienceFilterInput {
  description?: string | null;
  direction?: SortDirection | null;
  id?: string | null;
  isOrphanPage?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: ExperienceOrderBy | null;
  price?: FromToFilter | null;
  searchTerm?: string | null;
  sports?: number[] | null;
  statuses?: ExperienceStatus[] | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  storeStatuses?: StoreStatus[] | null;
  title?: string | null;
}

export interface ExperienceImageInput {
  experienceImageFileKey: string;
  isMainImage?: boolean | null;
}

export interface ExperiencePriceEstimateInput {
  requestedPrice: number;
}

export interface ExperienceSalesInput {
  createdAtDateRange?: FromToDatesFilter | null;
  customerEmail?: string | null;
  customerName?: string | null;
  direction?: SortDirection | null;
  experienceId?: string | null;
  id?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: ExperienceSalesOrderBy | null;
  statuses?: ExperienceRequestStatus[] | null;
  storeId?: string | null;
  storeName?: string | null;
  title?: string | null;
}

export interface FromToDatesFilter {
  from?: any | null;
  to?: any | null;
}

export interface FromToDatesStrictFilter {
  from: any;
  to: any;
}

export interface FromToFilter {
  from?: number | null;
  to?: number | null;
}

export interface GetAddressesInput {
  userId: string;
}

export interface GetAdminMerchProductsInput {
  color?: MerchProductColor[] | null;
  createdByRoles?: UserRole[] | null;
  direction?: SortDirection | null;
  gender?: Gender[] | null;
  genderV2?: GenderInputFilter[] | null;
  ids?: number[] | null;
  isCreatedByAdmin?: boolean | null;
  limit?: number | null;
  merchStatus?: MerchProductStatus | null;
  offset?: number | null;
  orderBy?: MerchProductOrderBy | null;
  price?: FromToFilter | null;
  searchTerm?: string | null;
  size?: string[] | null;
  sports?: number[] | null;
  storeId?: string | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  storeStatuses?: StoreStatus | null;
  title?: string | null;
  type?: MerchType[] | null;
}

export interface GetAgenciesInput {
  companyName?: string | null;
  direction?: SortDirection | null;
  email?: string | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: AgencyListOrderBy | null;
  statuses?: StoreStatus[] | null;
}

export interface GetAmaSalesInput {
  createdAtDateRange?: FromToDatesFilter | null;
  customerEmail?: string | null;
  customerName?: string | null;
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: AmaSalesOrderBy | null;
  storeId?: string | null;
  storeName?: string | null;
}

export interface GetAnyProductSalesInput {
  createdAtDateRange?: FromToDatesFilter | null;
  customerEmail?: string | null;
  customerId?: string | null;
  customerName?: string | null;
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: AnyProductSalesOrderBy | null;
  orderId?: string | null;
  orderIdStrict?: number | null;
  storeId?: string | null;
  subOrderStatuses?: MerchOrderStatus[] | null;
  subtotalRange?: FromToFilter | null;
  totalStoreRevenuesRange?: FromToFilter | null;
  types?: ProductSalesType[] | null;
}

export interface GetAvailableScheduleSlotsInput {
  dateRange: AvailableSlotsDateRangeInput;
  type?: ScheduleType | null;
  userId: string;
}

export interface GetCampaignOffersInput {
  ageRange?: FromToFilter | null;
  appliedAtRange?: FromToDatesFilter | null;
  approvedAtRange?: FromToDatesFilter | null;
  campaignId?: string | null;
  countries?: string[] | null;
  createdAtRange?: FromToDatesFilter | null;
  declinedAtRange?: FromToDatesFilter | null;
  direction?: SortDirection | null;
  genders?: CampaignOfferGenderFilter[] | null;
  isProductSent?: boolean | null;
  limit?: number | null;
  name?: string | null;
  offerIds?: string[] | null;
  offset?: number | null;
  orderBy?: CampaignOffersOrderBy | null;
  socialFollowingLiveRange?: FromToFilter | null;
  socialFollowingRange?: FromToFilter | null;
  socialMediaType?: SocialMediaType | null;
  sports?: string[] | null;
  states?: string[] | null;
  statuses?: CampaignOfferStatus[] | null;
  statusesV2?: CampaignOfferStatus[] | null;
  submittedAtRange?: FromToDatesFilter | null;
  totalFeeRange?: FromToFilter | null;
}

export interface GetCampaignsInput {
  agencyEmail?: string | null;
  agencyId?: string | null;
  agencyName?: string | null;
  brandName?: string | null;
  campaignId?: string | null;
  campaignIds?: string[] | null;
  createdAtRange?: FromToDatesFilter | null;
  direction?: SortDirection | null;
  dueDateRange?: FromToDatesFilter | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: CampaignsOrderBy | null;
  statuses?: CampaignStatus[] | null;
  types?: CampaignType[] | null;
  withPhysicalProduct?: boolean | null;
}

export interface GetCountryStatesInput {
  countryId: number;
}

export interface GetInterviewTaskInput {
  id: string;
}

export interface GetInterviewsInput {
  createdAtDateRange?: FromToDatesFilter | null;
  creationTypes?: InterviewCreationType[] | null;
  direction?: SortDirection | null;
  id?: string | null;
  intervieweeEmail?: string | null;
  intervieweeName?: string | null;
  interviewerId?: string | null;
  interviewerName?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: InterviewerTasksOrderBy | null;
  scheduleDateRange?: FromToDatesFilter | null;
  sources?: InterviewSourceType[] | null;
  statuses?: InterviewStatus[] | null;
}

export interface GetMediaPostInput {
  id?: string | null;
  slug?: string | null;
  storeSlug?: string | null;
}

export interface GetMediaPostsInput {
  direction?: SortDirection | null;
  ids?: string[] | null;
  isOrphanPage?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: MediaPostsOrderBy | null;
  searchTerm?: string | null;
  statuses?: MediaPostStatus[] | null;
  storeId?: string | null;
  storeSlug?: string | null;
  storeStatuses?: StoreStatus[] | null;
}

export interface GetMerchProductInput {
  id?: number | null;
  slug?: string | null;
  storeSlug?: string | null;
}

export interface GetMerchProductsInput {
  color?: MerchProductColor[] | null;
  createdByRoles?: UserRole[] | null;
  direction?: SortDirection | null;
  gender?: Gender[] | null;
  genderV2?: GenderInputFilter[] | null;
  ids?: number[] | null;
  isCreatedByAdmin?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: MerchProductOrderBy | null;
  price?: FromToFilter | null;
  searchTerm?: string | null;
  size?: string[] | null;
  sports?: number[] | null;
  storeId?: string | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  title?: string | null;
  type?: MerchType[] | null;
}

export interface GetMyInterviewsInput {
  createdAtDateRange?: FromToDatesFilter | null;
  direction?: SortDirection | null;
  id?: string | null;
  intervieweeEmail?: string | null;
  intervieweeName?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: InterviewerTasksOrderBy | null;
  scheduleDateRange?: FromToDatesFilter | null;
  statuses?: InterviewStatus[] | null;
}

export interface GetMyScheduleEventsInput {
  dateRange: FromToDatesStrictFilter;
  status?: ScheduleEventStatus[] | null;
  type?: ScheduleType | null;
}

export interface GetMyScheduleInput {
  type?: ScheduleType | null;
}

export interface GetPlaceDetailsInput {
  id: string;
  sessionToken?: string | null;
}

export interface GetPlacePredictionsInput {
  query: string;
  sessionToken?: string | null;
}

export interface GetPrProductsInput {
  type: MerchType;
}

export interface GetPromoCodeInput {
  amaId?: string | null;
  customerId?: string | null;
  id?: string | null;
  merchIds?: number[] | null;
  name?: string | null;
  streamId?: string | null;
}

export interface GetPromocodeStatsFilter {
  dateRange?: FromToDatesFilter | null;
  direction?: SortDirection | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: PromocodesOrderBy | null;
  storeId?: string | null;
}

export interface GetRedirectsInput {
  direction?: SortDirection | null;
  from?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: GetRedirectsOrderBy | null;
  to?: string | null;
}

export interface GetScheduleEventsInput {
  dateRange: FromToDatesStrictFilter;
  status?: ScheduleEventStatus[] | null;
  type?: ScheduleType | null;
  userId: string;
}

export interface GetScheduleInput {
  type?: ScheduleType | null;
  userId: string;
}

export interface GetSportsInput {
  name?: string | null;
  storeRoles?: UserRole[] | null;
}

export interface GetStateCitiesInput {
  direction?: SortDirection | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  stateId: number;
}

export interface GetStreamClipInput {
  id: string;
}

export interface GetThankYouVideoTemplateInput {
  productType: ProductTypesType;
  storeId?: string | null;
}

export interface GetUnmergedMerchInput {
  direction?: SortDirection | null;
  isOrphanPage?: boolean | null;
  limit?: number | null;
  merchTitle?: string | null;
  offset?: number | null;
  orderBy?: UnmergedMerchOrderBy | null;
  storeEmail?: string | null;
  storeId?: string | null;
  storeName?: string | null;
  storeStatuses?: StoreStatus[] | null;
  types?: MerchType[] | null;
}

export interface GetUnregisteredContactsInput {
  direction?: SortDirection | null;
  email?: string | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: UnregisteredContactOrderBy | null;
  representativeName?: string | null;
  type?: ContactType | null;
}

export interface HashtagInput {
  name: string;
}

export interface HideStreamInput {
  id: string;
}

export interface HomePageDataSetupType {
  amas?: HomePageEntityString[] | null;
  athleteStories?: AthleteStoryInputType[] | null;
  ctaBanners: BannerInputType[];
  mainBanners: BannerInputType[];
  merch?: HomePageEntityNumber[] | null;
  stores?: HomePageEntityString[] | null;
  watchStreams?: HomePageEntityString[] | null;
}

export interface HomePageEntityNumber {
  id: number;
  orderIndex: number;
}

export interface HomePageEntityString {
  id: string;
  orderIndex: number;
}

export interface InPersonActivityInput {
  address: string;
  city: string;
  country?: string | null;
  coveredExpenses: CampaignActivityCoveredExpense[];
  description: string;
  duration: string;
  files?: CampaignExampleFileInput[] | null;
  instructions: string;
  possibleDates: any[];
  state: string;
  venueName: string;
  zip: string;
}

export interface InitEditedVideoMultipartUploadInput {
  contentType: string;
  fileExt: string;
  partCnt: number;
  videoEditRequestId: string;
}

export interface InitStreamMultipartUploadInput {
  contentType: string;
  fileExt: string;
  partCnt: number;
}

export interface InterviewDateInput {
  scheduleDate: any;
  timeZone: TimeZoneInput;
}

export interface InterviewDateV2Input {
  tz?: string | null;
  utc: any;
}

export interface InterviewHighValueMemorabiliaTriggerConfigInput {
  minPrice: number;
}

export interface InterviewHighValueMemorabiliaTriggerSettingInput {
  config: InterviewHighValueMemorabiliaTriggerConfigInput;
  interviewerIds?: string[] | null;
  isEnabled: boolean;
}

export interface InterviewHighValueMerchTriggerConfigInput {
  minPrice: number;
}

export interface InterviewHighValueMerchTriggerSettingInput {
  config: InterviewHighValueMerchTriggerConfigInput;
  interviewerIds?: string[] | null;
  isEnabled: boolean;
}

export interface InterviewPPVParticipantTriggerSettingInput {
  interviewerIds?: string[] | null;
  isEnabled: boolean;
}

export interface InterviewSourceInput {
  merchId?: number | null;
  productId?: string | null;
  streamId?: string | null;
  type: InterviewSourceType;
}

export interface InterviewUserRequestSettingInput {
  interviewerIds?: string[] | null;
  isEnabled?: boolean | null;
}

export interface ManagerRejectAndReassignMerchDesignInput {
  designRequestId: string;
  feedback: string;
  newDesignerId: string;
}

export interface ManagerRejectMerchDesignInput {
  designRequestId: string;
  feedback: string;
}

export interface MemorabiliaFilterInput {
  auctionStatuses?: AuctionStatus[] | null;
  createdByRoles?: UserRole[] | null;
  description?: string | null;
  direction?: SortDirection | null;
  fulfillment?: MemorabiliaFulfillment | null;
  id?: string | null;
  ids?: string[] | null;
  isCreatedByAdmin?: boolean | null;
  isMarkedInappropriate?: boolean | null;
  isOrphanPage?: boolean | null;
  limit?: number | null;
  numberOfUnits?: number | null;
  offset?: number | null;
  orderBy?: MemorabiliaOrderBy | null;
  price?: FromToFilter | null;
  productTypes?: MemorabiliaProductType[] | null;
  requestPrice?: FromToFilter | null;
  saleMethods?: MemorabiliaSaleMethod[] | null;
  searchTerm?: string | null;
  sports?: number[] | null;
  statuses?: MemorabiliaStatus[] | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  storeStatuses?: StoreStatus[] | null;
  title?: string | null;
}

export interface MemorabiliaImageInput {
  isMainImage?: boolean | null;
  memorabiliaImageFileKey: string;
}

export interface MerchCustomImageV2Input {
  isMain: boolean;
  key: string;
}

export interface MerchEstimatePrintFileSetInput {
  printFiles: VariantEstimatePrintFileInput[];
  variantSetIndices: number[];
}

export interface MerchProductColor {
  color?: string | null;
  colorCode?: string | null;
  colorCode2?: string | null;
}

export interface MerchProductCustomImageSetInput {
  customImages?: MerchCustomImageV2Input[] | null;
  variantSetIndices: number[];
}

export interface MerchProductPrintFileSetInput {
  printFiles: VariantPrintFileInput[];
  variantSetIndices: number[];
}

export interface MerchProductVariantSet {
  age: Age;
  gender: Gender;
  stitches?: string | null;
  threads?: string[] | null;
  variantIds: number[];
}

export interface MergeMerchInput {
  childMerchId: number[];
  parentMerchId: number;
  requestedProfit: number;
  title: string;
}

export interface ModeratorCreateInput {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  socialMediaLink?: string | null;
}

export interface ModeratorEditInput {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  moderatorId: string;
  password?: string | null;
  phoneNumber?: string | null;
  socialMediaLink?: string | null;
}

export interface ModeratorsFilterInput {
  direction?: SortDirection | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  limit?: number | null;
  moderatorIds?: string[] | null;
  offset?: number | null;
  orderBy?: ModeratorsOrderBy | null;
  phoneNumber?: number | null;
  status?: StoreStatus[] | null;
}

export interface MySalesInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: StreamOrderSalesOrderBy | null;
  storeId?: string | null;
}

export interface PaginatedStreamOrdersInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  streamId: string;
}

export interface PastAppearanceStreamInput {
  description: string;
  hashtagInputs?: HashtagInput[] | null;
  imageFileKey?: string | null;
  mentionsInputs?: CreateMentionInput[] | null;
  name?: string | null;
  records: AppearanceStreamRecordInput[];
  scheduleDate?: any | null;
  scheduleDateV2?: DateV2Input | null;
  timeZone?: TimeZoneInput | null;
}

export interface PayoutOrdersFilterInput {
  id?: string | null;
  limit?: number | null;
  offset?: number | null;
  periodFrom: any;
  periodTo: any;
  storeId?: string | null;
}

export interface PrintImageFilterInput {
  storeId: string;
}

export interface PrintImageInput {
  printImageId: string;
  userId?: string | null;
}

export interface PrintImageUploadInput {
  designRequestId?: string | null;
  image: any;
  userId?: string | null;
}

export interface ProductPriceEstimateInput {
  changedStock?: number | null;
  fulfillment?: MemorabiliaFulfillment | null;
  numberOfUnits?: number | null;
  productId?: string | null;
  requestedPrice: number;
}

export interface ProductPrintFilePlacementInput {
  angle: number;
  left: number;
  printImageId: string;
  rotatedLeft: number;
  rotatedTop: number;
  scaleX: number;
  scaleY: number;
  top: number;
}

export interface PromoCodesFilterInput {
  amount?: FromToFilter | null;
  chargeSubject?: PromoCodesChargeSubject[] | null;
  direction?: SortDirection | null;
  endDate?: FromToDatesFilter | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: PromoCodesOrderBy | null;
  startDate?: FromToDatesFilter | null;
}

export interface PsychosocialKeywordFilterInput {
  direction?: SortDirection | null;
  keyword?: string | null;
  limit?: number | null;
  names?: string[] | null;
  offset?: number | null;
  orderBy?: PsychosocialKeywordOrderBy | null;
}

export interface ReassignVideoEditRequestInput {
  feedback?: string | null;
  newVideoEditorId: string;
  videoEditRequestId: string;
  videoEditorId?: string | null;
}

export interface RecoverExperienceInput {
  id: string;
}

export interface RecoverMemorabiliaInput {
  id: string;
  storeId?: string | null;
}

export interface RecoverStreamInput {
  id: string;
}

export interface RefreshTokensInput {
  refreshToken: string;
}

export interface ReinstateRejectedStoreInput {
  storeId: string;
}

export interface RejectAwaitingStoreInput {
  storeId: string;
}

export interface RejectDeliverableInput {
  agencyFeedback: string;
  deliverableId: string;
  offerId: string;
  ownerId?: string | null;
}

export interface RemoveInterviewerCapabilityInput {
  storeId: string;
}

/**
 * Reset interview from Completed/Scheduled to ScheduleAttempted
 */
export interface ResetInterviewAdminInput {
  id: string;
}

export interface ResetStreamInput {
  id: string;
}

export interface ReuploadEditedVideoInput {
  acceptedStoreIds?: string[] | null;
  body: string;
  editedVideoId: string;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  mentionsInputs?: CreateMentionInput[] | null;
  thumbnailKey: string;
  title: string;
  video: CreateEditedVideoInput;
}

export interface SalesReportInput {
  endDate?: any | null;
  startDate: any;
}

export interface ScheduleAvailabilityIntervalInput {
  from: string;
  to: string;
}

export interface ScheduleDateAvailabilityInput {
  date: string;
  intervals: ScheduleAvailabilityIntervalInput[];
}

export interface ScheduleDateInput {
  at: any;
  offset?: number | null;
  tzCode?: string | null;
}

export interface ScheduleInterviewAdminInput {
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  imageFileKey?: string | null;
  mentionsInputs?: CreateMentionInput[] | null;
  name?: string | null;
  scheduleDate?: InterviewDateInput | null;
  scheduleDateV2?: InterviewDateV2Input | null;
}

export interface ScheduleInterviewInput {
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  imageFileKey?: string | null;
  mentionsInputs?: CreateMentionInput[] | null;
  name?: string | null;
  scheduleDate?: InterviewDateInput | null;
  scheduleDateV2?: InterviewDateV2Input | null;
}

export interface ScheduleWeekDayAvailabilityInput {
  intervals: ScheduleAvailabilityIntervalInput[];
  weekday: WeekDay;
}

export interface ScheduledPostDateV2Input {
  tz?: string | null;
  utc: any;
}

export interface SearchCampaignCriteriaInput {
  budget: number;
  direction?: SortDirection | null;
  flatFeePerStore: number;
  genders?: StoreGender[] | null;
  locations?: CampaignLocationInput[] | null;
  maxAge?: number | null;
  minAge?: number | null;
  orderBy?: SearchCampaignStoresOrderBy | null;
  roles?: UserRole[] | null;
  smPlatforms: CampaignSmPlatform[];
  socialFollowersRange?: FromToFilter | null;
  sports: string[];
}

export interface SearchCampaignStoresInput {
  criteria: SearchCampaignCriteriaInput;
  limit?: number | null;
  offset?: number | null;
}

export interface SearchSocialShareCampaignInput {
  criteria: SearchSocialShareCriteriaInput;
  limit?: number | null;
  offset?: number | null;
}

export interface SearchSocialShareCriteriaInput {
  budget: number;
  direction?: SortDirection | null;
  flatFeePerStore: number;
  genders?: StoreGender[] | null;
  locations?: CampaignLocationInput[] | null;
  maxAge?: number | null;
  minAge?: number | null;
  orderBy?: SearchCampaignStoresOrderBy | null;
  roles?: UserRole[] | null;
  smPlatforms: CampaignSmPlatform[];
  socialFollowersRange?: FromToFilter | null;
  sports: string[];
}

export interface SearchSponsoredAppearanceCriteriaInput {
  appearanceTargetType?: CampaignActivityTargetType | null;
  appearanceVideoType?: SponsoredAppearanceVideoType | null;
  budget: number;
  direction?: SortDirection | null;
  flatFeePerStore: number;
  genders?: StoreGender[] | null;
  locations?: CampaignLocationInput[] | null;
  maxAge?: number | null;
  minAge?: number | null;
  orderBy?: SearchCampaignStoresOrderBy | null;
  roles?: UserRole[] | null;
  socialFollowersRange?: FromToFilter | null;
  sports?: string[] | null;
  withAmplify?: boolean | null;
}

export interface SearchSponsoredAppearanceInput {
  criteria: SearchSponsoredAppearanceCriteriaInput;
  limit?: number | null;
  offset?: number | null;
}

export interface SendManualInterviewInvitesInput {
  adminNotes?: string | null;
  intervieweeIds: string[];
  interviewerId?: string | null;
  source: InterviewSourceInput;
  streamImageKey?: string | null;
  streamTitle?: string | null;
}

export interface SendMerchDesignToReviewInput {
  designRequestId: string;
  socialMediaPackImages?: SocialMediaPackImageInput[] | null;
}

export interface SetBrandDealSettingsInput {
  contactEmail?: string | null;
  contactPhone?: string | null;
  disabledCategories?: string[] | null;
  isAcceptAll?: boolean | null;
  isAcceptingFreeProducts?: boolean | null;
  isArticlesPrEnabled?: boolean | null;
  minBookMePrice?: number | null;
  minFlatPrice?: number | null;
  minSocialPrice?: number | null;
  minSocialSharePrice?: number | null;
  minSponsoredLivePrice?: number | null;
  ownerId?: string | null;
}

export interface SetInterviewSettingsInput {
  HighValueMemorabiliaTrigger?: InterviewHighValueMemorabiliaTriggerSettingInput | null;
  HighValueMerchTrigger?: InterviewHighValueMerchTriggerSettingInput | null;
  PPVParticipantTrigger?: InterviewPPVParticipantTriggerSettingInput | null;
  UserRequest?: InterviewUserRequestSettingInput | null;
}

export interface SetInterviewerCapabilityInput {
  isIncludedInAutoAssignment: boolean;
  scheduleLink?: string | null;
  sportIds?: number[] | null;
  storeId: string;
}

export interface SetMyScheduleInput {
  dates?: ScheduleDateAvailabilityInput[] | null;
  durationMinutes?: number | null;
  minimumNoticeMinutes?: number | null;
  slotStepMinutes?: number | null;
  timezone?: string | null;
  type?: ScheduleType | null;
  weekdays?: ScheduleWeekDayAvailabilityInput[] | null;
}

export interface SetScheduleInput {
  dates?: ScheduleDateAvailabilityInput[] | null;
  durationMinutes?: number | null;
  minimumNoticeMinutes?: number | null;
  slotStepMinutes?: number | null;
  timezone?: string | null;
  type?: ScheduleType | null;
  userId: string;
  weekdays?: ScheduleWeekDayAvailabilityInput[] | null;
}

export interface SetVideoEditRequestSettingsInput {
  InterviewTrigger?: VideoEditRequestBooleanTriggerSettingInput | null;
  MediaLabTrigger?: VideoEditRequestTriggerSettingInput | null;
  SponsoredAppearanceTrigger?: VideoEditRequestBooleanTriggerSettingInput | null;
  StreamTrigger?: VideoEditRequestTriggerSettingInput | null;
}

export interface SignInInput {
  email: string;
  password: string;
}

export interface SignOutInput {
  refreshToken: string;
}

export interface SocialActivityInput {
  avoid?: string | null;
  brandName?: string | null;
  encouraged?: string | null;
  files?: CampaignExampleFileInput[] | null;
  hashtags?: string[] | null;
  instructions?: string | null;
  linkBio?: boolean | null;
  linkBioDurationDays?: number | null;
  mediaType?: CampaignMediaType[] | null;
  physicalProduct?: boolean | null;
  physicalProductType?: string | null;
  pinnedPost?: boolean | null;
  pinnedPostDurationDays?: number | null;
  postDueFrom?: any | null;
  postDueTo?: any | null;
  productName?: string | null;
  productValue?: number | null;
  profileMentions?: string | null;
  promoCodes?: string[] | null;
  promoUrls?: string[] | null;
  quantityInstructions?: string | null;
  script?: string | null;
  submissionDue?: any | null;
  suggestedCaption?: string | null;
  tasks?: CreateCampaignTaskInput[] | null;
  website?: string | null;
}

export interface SocialMediaPackImageInput {
  imageFileKey: string;
  imgURL?: string | null;
  label?: string | null;
}

export interface SocialShareActivityDetailsInput {
  brandName?: string | null;
  description?: string | null;
  files?: CampaignExampleFileInput[] | null;
  instructions?: string | null;
  shareUrl: string;
  website?: string | null;
}

export interface SocialShareActivityInput {
  socialShare?: SocialShareActivityDetailsInput | null;
  type?: CampaignActivityType | null;
}

export interface SponsoredAppearanceActivityDetailsInput {
  guest?: SponsoredAppearanceGuestTargetInput | null;
  product?: SponsoredAppearanceProductTargetInput | null;
  target: CampaignActivityTargetType;
  videoType: SponsoredAppearanceVideoType;
  withAmplify?: boolean | null;
}

export interface SponsoredAppearanceActivityInput {
  sponsoredAppearance?: SponsoredAppearanceActivityDetailsInput | null;
  type?: CampaignActivityType | null;
}

export interface SponsoredAppearanceGuestTargetInput {
  bio: string;
  company?: string | null;
  email: string;
  instagram?: string | null;
  instructions: string;
  linkedin?: string | null;
  name: string;
  otherSocial?: string | null;
}

export interface SponsoredAppearanceProductTargetInput {
  bio?: string | null;
  files?: CampaignExampleFileInput[] | null;
  instagram?: string | null;
  instructions: string;
  linkedin?: string | null;
  name: string;
  otherSocial?: string | null;
  physicalProduct?: boolean | null;
  physicalProductType?: string | null;
  productName?: string | null;
  productValue?: number | null;
  website?: string | null;
}

export interface StoreDetailsInput {
  athleticLevel?: StoreAthleticLevel | null;
  avatar?: any | null;
  avatarFileKey?: string | null;
  bio?: string | null;
  birthDate?: string | null;
  careerStatus?: StoreCareerStatus | null;
  city?: string | null;
  collegeId?: string | null;
  country?: string | null;
  cover?: any | null;
  description?: string | null;
  facebookFollowing?: number | null;
  facebookLink?: string | null;
  favoriteNumber?: number | null;
  gender?: StoreGender | null;
  gymRole?: string | null;
  hometown?: string | null;
  hometownCountry?: string | null;
  hometownLocation?: EarthCoordinateInput | null;
  hometownState?: string | null;
  instagramFollowing?: number | null;
  instagramLink?: string | null;
  isBirthDayKnown?: boolean | null;
  isBirthMonthKnown?: boolean | null;
  leagueId?: string | null;
  location?: EarthCoordinateInput | null;
  organizationType?: OrganizationType | null;
  otherCollege?: string | null;
  otherLeague?: string | null;
  otherTeam?: string | null;
  signUpFlow?: SignUpFlow | null;
  socialMediaLink?: string | null;
  state?: string | null;
  storeName?: string | null;
  storeTitle?: string | null;
  subtype?: AccountSubType | null;
  teamId?: string | null;
  tiktokFollowing?: number | null;
  tiktokLink?: string | null;
  twitterFollowing?: number | null;
  twitterLink?: string | null;
  website?: string | null;
  youtubeFollowing?: number | null;
  youtubeLink?: string | null;
}

export interface StoreEditAdminInput {
  storeDetails?: StoreDetailsInput | null;
  storeId?: string | null;
  storeManagementDetails?: StoreManagementDetailsInput | null;
  userDetails?: UserDetailsInput | null;
}

export interface StoreGetInput {
  id?: string | null;
  slug?: string | null;
}

export interface StoreInterviewerFilterInput {
  direction?: SortDirection | null;
  email?: string | null;
  firstName?: string | null;
  interviewerIds?: string[] | null;
  lastName?: string | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: StoreInterviewerOrderBy | null;
  phoneNumber?: number | null;
  status?: StoreStatus[] | null;
}

export interface StoreManagementDetailsInput {
  adConnected: boolean;
  affinities?: string | null;
  agencyName?: string | null;
  agencyWebsite?: string | null;
  agentEmail?: string | null;
  agentName?: string | null;
  appearance?: string | null;
  conflictsOfInterestsOrNonInterests?: string | null;
  currentSponsors?: string | null;
  designRequestsPerMonthLimit?: number | null;
  facebookFollowing?: number | null;
  gender?: StoreGender | null;
  inbound?: string | null;
  initialAssessment?: number | null;
  instagramFollowing?: number | null;
  isBirthDayKnown?: boolean | null;
  isBirthMonthKnown?: boolean | null;
  latestAssessment?: number | null;
  managedById?: string | null;
  pressConferenceOrAmplifiedInterview?: string | null;
  signing?: string | null;
  socialConnected: boolean;
  socialPost?: string | null;
  tiktokFollowing?: number | null;
  twitterFollowing?: number | null;
  watchParty?: string | null;
  youtubeFollowing?: number | null;
}

export interface StorePromoCodePayloadInput {
  storeIds: string[];
}

export interface StoreStatusInput {
  storeId: string;
}

export interface StoresFilterInput {
  areaOfInfluenceIds?: string[] | null;
  direction?: SortDirection | null;
  hasActiveAma?: boolean | null;
  hasActiveExperiences?: boolean | null;
  hasActiveMerch?: boolean | null;
  hasActiveProducts?: boolean | null;
  hasActiveStreams?: boolean | null;
  hasAreaOfInfluence?: boolean | null;
  hasBirthDate?: boolean | null;
  hasPsychosocialKeywords?: boolean | null;
  hashtagIds?: number[] | null;
  id?: string | null;
  isShoppingEnabled?: boolean | null;
  isStreamer?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: StoresOrderBy | null;
  psychosocialKeywordsIds?: string[] | null;
  searchTerm?: string | null;
  slug?: string | null;
  sportId?: number | null;
  sports?: number[] | null;
  status?: StoreStatus[] | null;
  storeIds?: string[] | null;
  storeName?: string | null;
}

export interface StreamCreateInput {
  description: string;
  hashtagInputs?: HashtagInput[] | null;
  image?: any | null;
  imageFileKey?: string | null;
  isFree?: boolean | null;
  isHidden?: boolean | null;
  isPPV?: boolean | null;
  mentionsInputs?: CreateMentionInput[] | null;
  moderatorId?: string | null;
  name?: string | null;
  participantIds?: string[] | null;
  repeatingTitle?: string | null;
  repeatsEveryEnum?: StreamIntervalEnum | null;
  requestedPrice?: number | null;
  scheduleDate?: any | null;
  scheduleDateV2?: DateV2Input | null;
  sponsors?: StreamCreateSponsorsInput[] | null;
  sportIds?: number[] | null;
  timeZone?: TimeZoneInput | null;
}

export interface StreamCreateSponsorsInput {
  id?: string | null;
  imageFile?: any | null;
  imageFileKey?: string | null;
  logo?: string | null;
  name: string;
  promoMessage?: string | null;
}

export interface StreamFilterInput {
  accountTypes?: AccountType[] | null;
  direction?: SortDirection | null;
  haveSponsor?: boolean | null;
  id?: string | null;
  ids?: string[] | null;
  isPersonalized?: boolean | null;
  limit?: number | null;
  moderatorId?: string | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: StreamOrderBy | null;
  price?: FromToFilter | null;
  priceCategories?: StreamPriceCategory[] | null;
  publicStreamCategory?: PublicStreamCategory[] | null;
  scheduleDate?: FromToDatesFilter | null;
  searchTerm?: string | null;
  slug?: string | null;
  sportIds?: number[] | null;
  storeId?: string | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  storeStatus?: StoreStatus | null;
  streamStatus?: StreamStatus[] | null;
  streamSubtypeFilter?: StreamSubtypeFilter[] | null;
  tzcodes?: string[] | null;
  withCopies?: boolean | null;
  withDeleted?: boolean | null;
}

export interface StreamImageItemInput {
  contentType: string;
  ext: string;
}

export interface StreamNoteCreateInput {
  note: string;
  relativeTs?: number | null;
  streamId: string;
  streamRecordId?: number | null;
}

export interface StreamOrderAndTipsTypeInput {
  customerEmail?: string | null;
  customerName?: string | null;
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: MyTipsAndOrdersSalesOrderBy | null;
  paidAtDateRange?: FromToDatesFilter | null;
  storeId?: string | null;
}

export interface StreamOrdersQueryInput {
  createdAtDateRange?: FromToDatesFilter | null;
  customerId: string;
  direction?: SortDirection | null;
  email?: string | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: StreamOrderByFilter | null;
  orderStatus?: StreamOrderStatus[] | null;
  streamStatus?: StreamStatus[] | null;
}

export interface StreamParticipantInviteInputAdmin {
  storeId: string;
  streamId: string;
}

export interface StreamSalesInput {
  direction?: SortDirection | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: StreamSalesOrderBy | null;
  scheduledDateRange?: FromToDatesFilter | null;
  storeId?: string | null;
  storeName?: string | null;
}

export interface StreamUpdateInput {
  description: string;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  image?: any | null;
  imageFileKey?: string | null;
  isHidden?: boolean | null;
  isPPV?: boolean | null;
  mentionsInputs?: CreateMentionInput[] | null;
  moderatorId?: string | null;
  name?: string | null;
  participantIds?: string[] | null;
  repeatingTitle?: string | null;
  repeatsEveryEnum?: StreamIntervalEnum | null;
  scheduleDate?: any | null;
  scheduleDateV2?: DateV2Input | null;
  sponsors?: StreamCreateSponsorsInput[] | null;
  sportIds?: number[] | null;
  timeZone?: TimeZoneInput | null;
}

export interface SubmitCampaignDeliverablesForReviewInput {
  files: DeliverableFileInput[];
  offerId: string;
  ownerId?: string | null;
  postCopy?: string | null;
}

export interface SubmitCampaignOfferResultsInput {
  offerId: string;
  ownerId?: string | null;
  submissions?: CampaignOfferSubmissionInput[] | null;
}

export interface TimeZoneInput {
  offset?: number | null;
  tzCode?: string | null;
}

export interface UnhideStreamInput {
  id: string;
}

export interface UpdateAddressInput {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  company?: string | null;
  country?: string | null;
  countryCode?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  isDefault?: boolean | null;
  lastName?: string | null;
  phone?: string | null;
  phoneNumberE164?: string | null;
  state?: string | null;
  stateCode?: string | null;
  zipCode?: string | null;
}

export interface UpdateArticleInput {
  articleUrl?: string | null;
  content?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  imageLabel?: string | null;
  key?: string | null;
  mentionsInputs?: CreateMentionInput[] | null;
  status?: ArticleStatus | null;
  storeIds?: string[] | null;
  title?: string | null;
}

export interface UpdateBookMeCampaignInput {
  activities: BookMeActivityInput[];
  brandName?: string | null;
  dueDate: any;
  flatFeePerStore: number;
  id: string;
  name: string;
  ownerId?: string | null;
  talentId: string;
  usageRights?: boolean | null;
  usageRightsText?: string | null;
}

export interface UpdateCampaignInput {
  criteria?: SearchCampaignCriteriaInput | null;
  details: CreateCampaignDetailsInput;
  id: string;
}

export interface UpdateCampaignOwnershipInput {
  campaignId: string;
  newOwnerId: string;
}

export interface UpdateEventMarketingCampaignInput {
  activities: EventMarketingCampaignActivityInput[];
  id: string;
  name: string;
  ownerId?: string | null;
}

export interface UpdateExperienceInput {
  comment?: string | null;
  description?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  images: ExperienceImageInput[];
  numberOfUnits?: number | null;
  requestedPrice: number;
  title: string;
}

export interface UpdateInterviewInfoAdminInput {
  adminNotes?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  imageFileKey?: string | null;
  mentionsInputs?: CreateMentionInput[] | null;
  name?: string | null;
  scheduledMeetingLink?: string | null;
  source?: InterviewSourceInput | null;
}

export interface UpdateInterviewInfoInput {
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  imageFileKey?: string | null;
  mentionsInputs?: CreateMentionInput[] | null;
  name?: string | null;
  notes?: string | null;
  scheduledMeetingLink?: string | null;
}

export interface UpdateMemorabiliaInput {
  addressId?: string | null;
  auctionDateRange?: AuctionFromToInput | null;
  changedStock?: number | null;
  comment?: string | null;
  customsDescription?: string | null;
  description?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  height?: number | null;
  id: string;
  images: MemorabiliaImageInput[];
  length?: number | null;
  requestedPrice: number;
  shipmentCategory: string;
  storeId: string;
  title: string;
  weight?: number | null;
  width?: number | null;
}

export interface UpdateSocialShareCampaignInput {
  activities: SocialShareActivityInput[];
  criteria?: SearchSocialShareCriteriaInput | null;
  dueDate: any;
  id: string;
  name: string;
  ownerId?: string | null;
  usageRights?: boolean | null;
  usageRightsText?: string | null;
}

export interface UpdateSponsoredAppearanceInput {
  activities: SponsoredAppearanceActivityInput[];
  criteria?: SearchSponsoredAppearanceCriteriaInput | null;
  dueDate: any;
  id: string;
  name: string;
  ownerId?: string | null;
  usageRights?: boolean | null;
  usageRightsText?: string | null;
}

export interface UpdateVideoEditInfoInput {
  adminNotes?: string | null;
  body?: string | null;
  dueDate?: any | null;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  mentionsInputs?: CreateMentionInput[] | null;
  notes?: string | null;
  status?: VideoEditRequestStatus | null;
  title?: string | null;
}

export interface UploadEditedVideo {
  body: string;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  mentionsInputs?: CreateMentionInput[] | null;
  thumbnailKey: string;
  title: string;
  video: CreateEditedVideoInput;
}

export interface UserDetailsInput {
  areaOfInfluenceIds?: string[] | null;
  email?: string | null;
  firstName?: string | null;
  hashtagIds?: number[] | null;
  hashtagInputs?: HashtagInput[] | null;
  isOrphanPage?: boolean | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  phoneNumberE164?: string | null;
  phoneOptIn?: boolean | null;
  psychosocialKeywordsIds?: string[] | null;
  redirects?: string[] | null;
  sportIds?: number[] | null;
  username?: string | null;
}

export interface UserPayoutInput {
  amount: number;
  userId: string;
}

export interface UserUpdateInput {
  avatarKey?: string | null;
  email?: string | null;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  phoneNumberE164?: string | null;
  phoneOptIn?: boolean | null;
  username?: string | null;
}

export interface UsersFilterInput {
  direction?: SortDirection | null;
  email?: string | null;
  emails?: string[] | null;
  facebookLink?: string | null;
  firstName?: string | null;
  hasAma?: boolean | null;
  hasMerch?: boolean | null;
  hasStream?: boolean | null;
  id?: string | null;
  instagramLink?: string | null;
  isDeleteRequested?: boolean | null;
  isOnboarded?: boolean | null;
  lastName?: string | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: UsersOrderFields | null;
  phoneNumber?: number | null;
  role?: UserRole | null;
  slug?: string | null;
  socialMediaLink?: string | null;
  sportId?: number | null;
  status?: StoreStatus[] | null;
  tiktokLink?: string | null;
  twitterLink?: string | null;
  types?: AccountType[] | null;
}

export interface VariantEstimatePrintFileInput {
  placementType: PlacementTypes;
}

export interface VariantPrintFileInput {
  key: string;
  placementType: PlacementTypes;
  printFilePlacements: ProductPrintFilePlacementInput[];
}

export interface VariantPrintfilePresignedUrlInput {
  contentType: string;
  ext: string;
}

export interface VideoCroppedArea {
  height: number;
  width: number;
  x: number;
  y: number;
  zoom: number;
}

export interface VideoCroppedInput {
  area?: VideoCroppedArea | null;
  file: any;
  type?: string | null;
}

export interface VideoEditRequestAction {
  feedback?: string | null;
  videoEditRequestId: string;
  videoEditorId?: string | null;
}

export interface VideoEditRequestAdminInput {
  additionalStoreIds?: string[] | null;
  adminNotes?: string | null;
  dueDate?: any | null;
  hashtagInputs?: HashtagInput[] | null;
  mentionsInputs?: CreateMentionInput[] | null;
  rawUrls: string[];
  storeId: string;
  videoEditorId: string;
}

export interface VideoEditRequestBooleanTriggerSettingInput {
  isEnabled: boolean;
}

export interface VideoEditRequestTriggerSettingConfigInput {
  duration: number;
}

export interface VideoEditRequestTriggerSettingInput {
  config: VideoEditRequestTriggerSettingConfigInput;
  isEnabled: boolean;
}

export interface VideoEditRequestsFilterInput {
  direction?: SortDirection | null;
  isOverdue?: boolean | null;
  isUnassigned?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: VideoEditRequestsOrderBy | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  videoEditRequestId?: string | null;
  videoEditRequestStatuses?: VideoEditRequestStatus[] | null;
  videoEditorIds?: string[] | null;
  videoEditorName?: string | null;
}

export interface VideoEditorCreateInput {
  email: string;
  firstName: string;
  lastName: string;
}

export interface VideoEditorDetailsEditInput {
  isIncludedInAutoAssignment?: boolean | null;
}

export interface VideoEditorEditInput {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  phoneNumber?: string | null;
  socialMediaLink?: string | null;
  videoEditorDetails?: VideoEditorDetailsEditInput | null;
  videoEditorId?: string | null;
}

export interface VideoEditorFilterInput {
  direction?: SortDirection | null;
  email?: string | null;
  hasActiveVideoEditRequests?: boolean | null;
  limit: number;
  offset: number;
  orderBy: VideoEditorOrderBy;
  videoEditorIds?: string[] | null;
  videoEditorName?: string | null;
  videoEditorStatus?: StoreStatus[] | null;
}

export interface VirtualAppearanceActivityInput {
  description: string;
  duration: string;
  files?: CampaignExampleFileInput[] | null;
  instructions: string;
  location: string;
  possibleDates: any[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
