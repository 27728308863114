import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form, Button, Modal } from 'antd';
// Api
import {
  CREATE_CAMPAIGN_SUBMISSION_FILES,
  SUBMIT_CAMPAIGN_OFFER_RESULTS,
} from 'api/campaignV2/mutations';
// Types
import {
  GetCampaignOffers_getCampaignOffers_entities,
  GetCampaignOffers_getCampaignOffers_entities_campaign_activities_SocialShareActivity,
} from 'api/campaignV2/types/GetCampaignOffers';
import {
  CampaignOfferStatus,
  CampaignOfferSubmissionInput,
  CampaignTaskType,
  SubmitCampaignOfferResultsInput,
} from 'api/graphql-global-types';
import {
  CreateCampaignSubmissionFiles,
  CreateCampaignSubmissionFilesVariables,
} from 'api/campaignV2/types/CreateCampaignSubmissionFiles';
import {
  SubmitCampaignOfferResults,
  SubmitCampaignOfferResultsVariables,
} from 'api/campaignV2/types/SubmitCampaignOfferResults';
// Helpers
import {
  createPresignedUrlsAndUploadToS3,
  PresignedUrlResultItem,
} from 'helpers/single-uploader';
import {
  EMPTY_SOCIAL_SHARE_STATE,
  getInitialSocialShareState,
  getSocialShareSubmission,
  SocialShareData,
} from 'helpers/deliverables';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import SocialShareDeliverablesInput from './SocialShareDeliverablesInput/SocialShareDeliverablesInput';
// Styles
import styles from './SocialShareDeliverables.module.scss';

type SocialShareDeliverablesProps = {
  record: GetCampaignOffers_getCampaignOffers_entities | null;
  onClose: () => void;
  onFinish: () => void;
};

const SocialShareDeliverables = ({
  record,
  onClose,
  onFinish,
}: SocialShareDeliverablesProps): JSX.Element => {
  const [form] = Form.useForm();
  const campaignActivity = record?.campaign
    .activities?.[0] as GetCampaignOffers_getCampaignOffers_entities_campaign_activities_SocialShareActivity;

  const postTypes = campaignActivity?.tasks?.map((task) => task.type);

  const isIGShare = postTypes?.includes(CampaignTaskType.IG_SHARE) || false;
  const isTTShare = postTypes?.includes(CampaignTaskType.TT_SHARE) || false;
  const isTwitterShare =
    postTypes?.includes(CampaignTaskType.TW_SHARE) || false;
  // below will be added at some point, just uncomment when ready
  //   const isTWShare = postTypes?.includes(CampaignTaskType.TW_SHARE) || false;
  //   const isFBShare = postTypes?.includes(CampaignTaskType.FB_SHARE) || false;
  //   const isYTShare = postTypes?.includes(CampaignTaskType.YT_SHARE) || false;

  // update below for more types when they are ready
  const type: CampaignTaskType = isIGShare
    ? CampaignTaskType.IG_SHARE
    : isTTShare
    ? CampaignTaskType.TT_SHARE
    : CampaignTaskType.TW_SHARE;

  const [socialShareState, setSocialShareState] = useState<SocialShareData>(
    EMPTY_SOCIAL_SHARE_STATE
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [createCampaignSubmissionFiles] = useMutation<
    CreateCampaignSubmissionFiles,
    CreateCampaignSubmissionFilesVariables
  >(CREATE_CAMPAIGN_SUBMISSION_FILES);

  const [submitCampaignOffer] = useMutation<
    SubmitCampaignOfferResults,
    SubmitCampaignOfferResultsVariables
  >(SUBMIT_CAMPAIGN_OFFER_RESULTS);

  const handleFinish = () => {
    onFinish();
    handleReset();
  };

  const handleReset = () => {
    onClose();
    form.resetFields();
    setIsLoading(false);
    setSocialShareState(EMPTY_SOCIAL_SHARE_STATE);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    // check if there is a link or image before going to upload
    if (socialShareState.link === '' && socialShareState.images?.length === 0) {
      errorNotification('Please add link and/or image to submit');
      setIsLoading(false);

      return;
    }

    try {
      const input: SubmitCampaignOfferResultsInput = {
        offerId: record?.id || '',
        ownerId: record?.userId,
        submissions: [],
      };

      const imagesForUpload: File[] = [];
      if (socialShareState?.images?.[0]?.file) {
        imagesForUpload.push(socialShareState.images[0].file);
      }

      const imageKeys: PresignedUrlResultItem[] =
        await createPresignedUrlsAndUploadToS3({
          isStrict: true,
          files: imagesForUpload,
          withoutContentType: true,
          getPresignedUrls: async () => {
            const { data: customImagesPresignedUrls } =
              await createCampaignSubmissionFiles({
                variables: {
                  input: {
                    offerId: record?.id || '',
                    files: imagesForUpload.map((item) => ({
                      name: item.name,
                    })),
                  },
                },
              });
            if (!customImagesPresignedUrls) {
              console.error('Error creating presigned images');
              throw new Error('Something went wrong');
            }

            return customImagesPresignedUrls.createCampaignSubmissionFiles;
          },
        });

      const submission: CampaignOfferSubmissionInput[] =
        getSocialShareSubmission(socialShareState, imageKeys, type);

      input.submissions = [...submission];

      await submitCampaignOffer({
        variables: {
          input,
        },
      });
      successNotification(`Campaign deliverables added successfully`);

      handleFinish();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (record) {
      const initialSocialShareState = getInitialSocialShareState(record);
      setSocialShareState(initialSocialShareState);
    } else {
      setSocialShareState(EMPTY_SOCIAL_SHARE_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  // disable submit if all of the inputs are missing a value
  const disableSubmit =
    !!socialShareState.images?.[0] &&
    (socialShareState.link === '' || socialShareState.link === null);

  const isCompletedOrPublished =
    record?.statusV2 === CampaignOfferStatus.COMPLETED ||
    record?.statusV2 === CampaignOfferStatus.PUBLISHED;

  return (
    <Modal
      title="Deliverables"
      visible={!!record}
      footer={null}
      onCancel={handleReset}
      width="90vw"
    >
      <Form
        className={styles.root}
        name="createEditSocialShareCampaignDeliverables"
        aria-label="Create/Edit Social Share Campaign Deliverables"
        form={form}
        onFinish={handleSubmit}
      >
        {isIGShare && (
          <SocialShareDeliverablesInput
            label="Instagram"
            placeholder="https://instagram.com/hey..."
            isCompletedOrPublished={isCompletedOrPublished}
            link={socialShareState.link}
            images={socialShareState.images}
            setState={setSocialShareState}
          />
        )}
        {isTTShare && (
          <SocialShareDeliverablesInput
            label="Tiktok"
            placeholder="https://tiktok.com/hey..."
            isCompletedOrPublished={isCompletedOrPublished}
            link={socialShareState.link}
            images={socialShareState.images}
            setState={setSocialShareState}
          />
        )}
        {isTwitterShare && (
          <SocialShareDeliverablesInput
            label="X"
            placeholder="https://x.com/hey..."
            isCompletedOrPublished={isCompletedOrPublished}
            link={socialShareState.link}
            images={socialShareState.images}
            setState={setSocialShareState}
          />
        )}

        {!isCompletedOrPublished && (
          <div className={styles.submitButton}>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoading}
              disabled={isLoading || disableSubmit}
            >
              Save
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default SocialShareDeliverables;
