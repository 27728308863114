import React from 'react';
import moment from 'moment';
import { Image } from 'antd';
// Helpers
import { isImage } from 'helpers/isImage';
// Types
import {
  GetCampaignsV2_getCampaigns_entities,
  GetCampaignsV2_getCampaigns_entities_agency,
  GetCampaignsV2_getCampaigns_entities_activities_SocialActivity_files,
  GetCampaignsV2_getCampaigns_entities_search,
  GetCampaignsV2_getCampaigns_entities_activities_SocialActivity,
  GetCampaignsV2_getCampaigns_entities_activities_VirtualAppearanceActivity,
  GetCampaignsV2_getCampaigns_entities_activities_InPersonAppearanceActivity,
  GetCampaignsV2_getCampaigns_entities_activities_CustomActivity,
} from 'api/campaignV2/types/GetCampaignsV2';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// Icons
import { DownloadOutlined } from '@ant-design/icons';
// Style
import styles from './CampaignRecordData.module.scss';
import { CampaignSmPlatform } from 'api/graphql-global-types';

type CampaignRecordDataProps = {
  record: GetCampaignsV2_getCampaigns_entities;
};

const CampaignRecordData = ({
  record,
}: CampaignRecordDataProps): JSX.Element | null => {
  const socialActivity = record?.activities?.find(
    (item) => item.__typename === 'SocialActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SocialActivity;

  const virtualAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'VirtualAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_VirtualAppearanceActivity;

  const inPersonAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'InPersonAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_InPersonAppearanceActivity;

  const customActivity = record?.activities?.find(
    (item) => item.__typename === 'CustomActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_CustomActivity;

  const files =
    socialActivity?.files?.length > 0
      ? socialActivity.files
      : virtualAppearanceActivity?.files?.length > 0
      ? virtualAppearanceActivity.files
      : inPersonAppearanceActivity?.files?.length > 0
      ? inPersonAppearanceActivity.files
      : customActivity?.files?.length > 0
      ? customActivity.files
      : [];

  const brand = record?.agency as GetCampaignsV2_getCampaigns_entities_agency;
  const filters = record?.search as GetCampaignsV2_getCampaigns_entities_search;
  const locations = filters?.locations;

  return (
    <div className={styles.root}>
      <div>
        <p className={styles.label}>ID:</p>
        <p className={styles.value}>{record?.id}</p>
      </div>

      <div>
        <p className={styles.label}>Campaign Name:</p>
        <p className={styles.value}>{record?.name}</p>
      </div>

      <div>
        <p className={styles.label}>Usage Rights:</p>
        <p className={styles.value}>{record?.usageRights ? 'Yes' : 'No'}</p>
      </div>

      <div>
        <p className={styles.label}>Usage Rights Text:</p>
        <p className={styles.value}>{record?.usageRightsText || 'N/a'}</p>
      </div>

      <div>
        <p className={styles.label}>Brand email:</p>
        <p className={styles.value}>{brand.email}</p>
      </div>

      <div>
        <p className={styles.label}>Brand phone number:</p>
        <p className={styles.value}>{brand.phoneNumber}</p>
      </div>

      <div>
        <p className={styles.label}>Submit date:</p>
        <p className={styles.value}>
          {moment(record?.createdAt).format('MM/DD/YYYY')}
        </p>
      </div>

      <div>
        <p className={styles.label}>Application deadline:</p>
        <p className={styles.value}>
          {moment(record?.dueDate).format('MM/DD/YYYY')}
        </p>
      </div>

      <div>
        <p className={styles.label}>Total budget:</p>
        <p className={styles.value}> {record?.budget}</p>
      </div>

      <div>
        <p className={styles.label}>Budget remaining:</p>
        <p className={styles.value}> {record?.budgetLeft}</p>
      </div>

      <div>
        <p className={styles.label}>Cost per athlete:</p>
        <p className={styles.value}> {record?.flatFeePerStore}</p>
      </div>

      {record?.search?.smPlatforms?.[0] && (
        <div>
          <p className={styles.label}>Platform:</p>
          <p className={styles.value}>
            {record.search?.smPlatforms?.[0] === CampaignSmPlatform.TW
              ? 'X'
              : record.search?.smPlatforms?.[0]}
          </p>
        </div>
      )}

      {!!filters?.sports?.length && (
        <div>
          <p className={styles.label}>Sports:</p>
          <p className={styles.value}>
            {filters?.sports?.map((sport) => {
              return (
                <span key={sport} className={styles.value}>
                  {sport}
                </span>
              );
            })}
          </p>
        </div>
      )}

      {!!filters?.genders && (
        <div>
          <p className={styles.label}>Gender:</p>
          <p className={styles.value}> {filters.genders}</p>
        </div>
      )}

      {!!filters?.athleteMinAge && (
        <div>
          <p className={styles.label}>Athlete minimum age:</p>
          <p className={styles.value}> {filters.athleteMinAge}</p>
        </div>
      )}

      {!!filters?.athleteMaxAge && (
        <div>
          <p className={styles.label}>Athlete maximum age:</p>
          <p className={styles.value}> {filters.athleteMaxAge}</p>
        </div>
      )}

      {!!(filters?.minFollowers || filters?.maxFollowers) && (
        <div>
          <p className={styles.label}>Social media following:</p>
          <p className={styles.value}>
            {filters.minFollowers || filters.maxFollowers}
          </p>
        </div>
      )}

      {!!locations?.length && (
        <div>
          <p className={styles.label}>Locations:</p>
          <p className={styles.value}>
            {locations?.map((location, index) => {
              return (
                <div
                  key={location.country + '_' + index}
                  className={styles.value}
                >
                  {location.country && location.state
                    ? 'country: ' +
                      location.country +
                      ', state: ' +
                      location.state
                    : 'country: ' + location.country}
                </div>
              );
            })}
          </p>
        </div>
      )}

      <div>
        <p className={styles.label}>Examples:</p>
        {files?.length > 0 ? (
          files.map(
            (
              item: GetCampaignsV2_getCampaigns_entities_activities_SocialActivity_files
            ) => {
              const fileExtension = item?.key?.split('.')[1] || '';

              const isImageKey = isImage(fileExtension);
              return (
                <div key={item.key}>
                  {isImageKey ? (
                    <Image
                      fallback={FALLBACK_IMAGE}
                      key={item.key}
                      width={200}
                      height={200}
                      src={item.url || ''}
                      style={{ objectFit: 'cover' }}
                      alt={item.key || 'uploads'}
                    />
                  ) : (
                    <>
                      <a
                        key={item.key}
                        href={item.url}
                        download={item.key}
                        title={item.key}
                      >
                        <DownloadOutlined /> Download file
                      </a>
                    </>
                  )}
                </div>
              );
            }
          )
        ) : (
          <p>no example</p>
        )}
      </div>
    </div>
  );
};

export default CampaignRecordData;
