import React from 'react';
import moment from 'moment';
// Helpers
import { parseForInnerHTMLWithBreaks } from 'helpers/textEditor';
// Types
import { CBDeliverableStatus } from 'api/graphql-global-types';
import { GetCampaignOffers_getCampaignOffers_entities_deliverables } from 'api/campaignV2/types/GetCampaignOffers';
// Styles
import styles from './CampaignDeliverablesRevisionHistory.module.scss';

type CampaignDeliverablesRevisionHistoryProps = {
  deliverables: GetCampaignOffers_getCampaignOffers_entities_deliverables[];
};

const CampaignDeliverablesRevisionHistory = ({
  deliverables,
}: CampaignDeliverablesRevisionHistoryProps): JSX.Element | null => {
  return (
    <div className={styles.root}>
      <div className={styles.reviews}>
        {deliverables.map((item, index) => {
          const isApproved = item.status === CBDeliverableStatus.APPROVED;
          const isRejected = item.status === CBDeliverableStatus.REJECTED;
          const isOnReview = item.status === CBDeliverableStatus.ON_REVIEW;

          return (
            <div className={styles.itemWrapper} key={index}>
              <div className={styles.titleRow}>
                <p className={styles.title}>Revision #{index + 1}</p>
                {isApproved && <p className={styles.approved}>Approved </p>}
                {isRejected && (
                  <p className={styles.rejected}>Request Improvement </p>
                )}
                {isOnReview && <p className={styles.onReview}>On Review </p>}
              </div>
              <div className={styles.dateRow}>
                <p className={styles.dateLabel}>Date:</p>
                <p className={styles.dateValue}>
                  {moment(item.createdAt).format('MM/DD/YYYY')}
                </p>
              </div>
              {item.status === CBDeliverableStatus.REJECTED && (
                <>
                  <p>Brand Feedback:</p>
                  <p
                    className={styles.feedback}
                    dangerouslySetInnerHTML={{
                      __html: parseForInnerHTMLWithBreaks(item.agencyFeedback),
                    }}
                  />
                </>
              )}
              <>
                <p>Uploaded Media:</p>
                <div className={styles.mediaWrapper}>
                  {item.files.map((file, fileIndex) => {
                    const isImage = file.key?.match(/\.(jpeg|jpg|png)$/i);
                    const isVideo = file.key?.match(/\.(mp4)$/i);

                    return (
                      <a
                        key={index + '_' + fileIndex}
                        href={file?.url || ''}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        {isImage && 'Image  '}
                        {isVideo && 'Video  '}
                        {file.name}
                      </a>
                    );
                  })}
                </div>
              </>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CampaignDeliverablesRevisionHistory;
