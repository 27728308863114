import { GetPlaceDetails_getPlaceDetails } from 'api/places/types/GetPlaceDetails';
export const checkIsUSorCA = (input: string | null): boolean => {
  return (
    Boolean(input === 'United States' || input === 'Canada') ||
    Boolean(input === 'US' || input === 'CA')
  );
};

export const joinLocation = (input: (string | undefined | null)[]): string => {
  return input.filter(Boolean).join(', ');
};

export const createLocation = (
  latitude?: number | null,
  longitude?: number | null
): {
  latitude: number;
  longitude: number;
} | null =>
  latitude != null && longitude != null ? { latitude, longitude } : null;

export const handlePlaceSelect = (
  setFieldsValue: (values: any) => void,
  placeDetails: GetPlaceDetails_getPlaceDetails,
  type: 'hometown' | 'city'
): string => {
  const isUSOrCA = checkIsUSorCA(placeDetails.country);

  const fieldMap = {
    hometown: {
      city: 'hometown',
      state: 'hometownState',
      country: 'hometownCountry',
      latitude: 'hometownLatitude',
      longitude: 'hometownLongitude',
    },
    city: {
      city: 'city',
      state: 'state',
      country: 'country',
      location: 'location',
      latitude: 'latitude',
      longitude: 'longitude',
    },
  };

  setFieldsValue({
    [fieldMap[type].city]: placeDetails.city,
    [fieldMap[type].state]: isUSOrCA ? placeDetails.stateCode : null,
    [fieldMap[type].country]: placeDetails.countryCode,
    [fieldMap[type].latitude]: placeDetails.location.latitude,
    [fieldMap[type].longitude]: placeDetails.location.longitude,
  });

  return joinLocation([
    placeDetails.city,
    isUSOrCA ? placeDetails.stateCode : null,
    placeDetails.countryCode,
  ]);
};
