import React, { ChangeEvent, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
// Helpers
import { uploadDeliverables, UploadFileType } from 'helpers/campaign';
// API
import {
  CREATE_CAMPAIGN_DELIVERABLES_FILES,
  SUBMIT_DELIVERABLES_FOR_REVIEW,
} from 'api/campaignV2/mutations';
// Types
import {
  SubmitDeliverablesForReview,
  SubmitDeliverablesForReviewVariables,
} from 'api/campaignV2/types/SubmitDeliverablesForReview';
import {
  CreateCampaignDeliverableFiles,
  CreateCampaignDeliverableFilesVariables,
} from 'api/campaignV2/types/CreateCampaignDeliverableFiles';
import { GetCampaignOffers_getCampaignOffers_entities } from 'api/campaignV2/types/GetCampaignOffers';
// UiShared
import TextArea from 'uiShared/Textarea/Textarea';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './CampaignDeliverablesSubmitForReviewForm.module.scss';

const MAX_VIDEO_SIZE = 5 * 1024 * 1024 * 1024; // 5GB max video size
const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB max image size
const MAX_FILES = 10;

type CampaignDeliverablesSubmitForReviewFormProps = {
  offer: GetCampaignOffers_getCampaignOffers_entities | null;
  onFinish: () => void;
};

const CampaignDeliverablesSubmitForReviewForm = ({
  offer,
  onFinish,
}: CampaignDeliverablesSubmitForReviewFormProps): JSX.Element => {
  const [postCopyText, setPostCopyText] = useState<string>('');
  const [uploadFiles, setUploadFiles] = useState<UploadFileType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [createCampaignDeliverableFiles] = useMutation<
    CreateCampaignDeliverableFiles,
    CreateCampaignDeliverableFilesVariables
  >(CREATE_CAMPAIGN_DELIVERABLES_FILES);

  const [submitDeliverablesForReview] = useMutation<
    SubmitDeliverablesForReview,
    SubmitDeliverablesForReviewVariables
  >(SUBMIT_DELIVERABLES_FOR_REVIEW);

  const offerId = offer?.id || '';

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const { data: presignedUrls } = await createCampaignDeliverableFiles({
        variables: {
          input: {
            files: uploadFiles.map(({ name }) => ({
              name,
            })),
          },
        },
      });

      if (presignedUrls?.createCampaignDeliverableFiles.length) {
        const uploadedUrls = await uploadDeliverables(
          presignedUrls.createCampaignDeliverableFiles,
          uploadFiles
        );

        await submitDeliverablesForReview({
          variables: {
            input: {
              offerId,
              postCopy: postCopyText,
              files: uploadedUrls.map((item) => ({
                key: item.key,
                name: item.name,
              })),
            },
          },
        });
      }

      setIsLoading(false);
      successNotification('Items sent for review successfully.');
      handleFinish();
    } catch (error: any) {
      errorNotification(
        error?.message || 'Something went wrong. Please try again.'
      );
      setIsLoading(false);
    }
  };

  const handlePostCopyChange = (newValue: ChangeEvent<HTMLTextAreaElement>) => {
    const newPost = newValue.target.value;
    if (newPost !== '') {
      setPostCopyText(newPost);
    } else {
      setPostCopyText('');
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const validFiles = acceptedFiles.filter((file) => {
        const isVideo = file.type === 'video/mp4';
        const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
        const isValidSize = isVideo
          ? file.size <= MAX_VIDEO_SIZE
          : file.size <= MAX_IMAGE_SIZE;
        return (isVideo || isImage) && isValidSize;
      });

      const filesWithPreview: UploadFileType[] = validFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          fileName: file.name,
        });
      });

      const totalFiles = uploadFiles.length + filesWithPreview.length;

      if (totalFiles > MAX_FILES) {
        errorNotification(
          `Please upload up to ${MAX_FILES} files. You are trying to upload ${totalFiles}!`
        );
      } else setUploadFiles((prevFiles) => [...prevFiles, ...filesWithPreview]);
    },
    [uploadFiles.length]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES,
    accept: 'image/jpeg, image/png, video/mp4',
  });

  const handleReset = () => {
    setUploadFiles([]);
  };

  const handleFinish = () => {
    handleReset();
    onFinish();
  };

  const showClear = Boolean(uploadFiles.length);
  const isUploadDisabled = !uploadFiles.length || !postCopyText;

  return (
    <div className={styles.root}>
      <div className={styles.inputWrapper}>
        <p className={styles.label}>Post Copy</p>
        <TextArea
          placeholder="Please write the copy you'd like to use for your post(s). Make sure to review the campaign details carefully for any hashtags, accounts, links, or other required verbiage."
          name="postCopy"
          onChange={handlePostCopyChange}
          className={styles.textarea}
          rows={3}
        />
      </div>

      {showClear && (
        <Button loading={isLoading} onClick={handleReset}>
          Clear Files
        </Button>
      )}

      <div className={styles.dropzoneWrapper} {...getRootProps()}>
        <input {...getInputProps()} />
        <p className={styles.title}>Drag & drop or click to select files.</p>
        <p className={styles.subtitle}>
          Upload JPEG and PNG images or mp4 video files.
        </p>
        <div className={styles.previewsContainer}>
          {uploadFiles.map((file, index) => {
            const isImage = file.path?.match(/\.(jpeg|jpg|png)$/i);
            const isVideo = file.path?.match(/\.(mp4)$/i);

            return (
              <div key={index} className={styles.previewWrapper}>
                <p className={styles.fileName}>{file.name}</p>
                {isImage ? (
                  <img
                    src={file.preview}
                    alt={file.name}
                    className={styles.preview}
                  />
                ) : isVideo ? (
                  <video
                    src={file.preview}
                    controls
                    className={styles.preview}
                    crossOrigin="anonymous"
                  >
                    <track
                      kind="captions"
                      label="English captions"
                      srcLang="en"
                      src=""
                      default
                    />
                    <source src={file.preview} type={file.type} />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <p>Unsupported file type</p>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <Button
        disabled={isUploadDisabled}
        loading={isLoading}
        onClick={handleSubmit}
      >
        Upload Files
      </Button>
    </div>
  );
};

export default CampaignDeliverablesSubmitForReviewForm;
