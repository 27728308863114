import React from 'react';
// Helpers
import { getContentTypeText } from 'helpers/socialMediaContentTypes';
import { parseForInnerHTMLWithBreaks } from 'helpers/textEditor';
// Types
import {
  GetCampaignsV2_getCampaigns_entities,
  GetCampaignsV2_getCampaigns_entities_activities_CustomActivity,
  GetCampaignsV2_getCampaigns_entities_activities_InPersonAppearanceActivity,
  GetCampaignsV2_getCampaigns_entities_activities_SocialActivity,
  GetCampaignsV2_getCampaigns_entities_activities_SocialShareActivity,
  GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity,
  GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity_target_SponsoredAppearanceProduct,
  GetCampaignsV2_getCampaigns_entities_activities_VirtualAppearanceActivity,
} from 'api/campaignV2/types/GetCampaignsV2';
// Style
import styles from './CampaignActivityData.module.scss';

type CampaignActivityDataProps = {
  record: GetCampaignsV2_getCampaigns_entities;
};

const CampaignActivityData = ({
  record,
}: CampaignActivityDataProps): JSX.Element | null => {
  const socialActivity = record?.activities?.find(
    (item) => item.__typename === 'SocialActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SocialActivity;

  const socialShareActivity = record?.activities?.find(
    (item) => item.__typename === 'SocialShareActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SocialShareActivity;

  const sponsoredAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'SponsoredAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity;

  const virtualAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'VirtualAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_VirtualAppearanceActivity;

  const inPersonAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'InPersonAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_InPersonAppearanceActivity;

  const customActivity = record?.activities?.find(
    (item) => item.__typename === 'CustomActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_CustomActivity;

  const sponsoredAppearanceBio =
    sponsoredAppearanceActivity?.target?.__typename ===
    'SponsoredAppearanceGuest'
      ? sponsoredAppearanceActivity?.target?.guestBio
      : sponsoredAppearanceActivity?.target?.__typename ===
        'SponsoredAppearanceProduct'
      ? sponsoredAppearanceActivity?.target?.productBio
      : '';

  const sponsoredAppearanceName =
    sponsoredAppearanceActivity?.target?.__typename ===
    'SponsoredAppearanceGuest'
      ? sponsoredAppearanceActivity?.target?.guestName
      : sponsoredAppearanceActivity?.target?.__typename ===
        'SponsoredAppearanceProduct'
      ? sponsoredAppearanceActivity?.target?.brandName
      : '';

  const sponsoredAppearanceProduct =
    sponsoredAppearanceActivity?.target?.__typename ===
    'SponsoredAppearanceProduct'
      ? (sponsoredAppearanceActivity?.target as GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity_target_SponsoredAppearanceProduct)
      : undefined;

  const hasProduct =
    sponsoredAppearanceProduct ||
    (socialActivity && socialActivity?.physicalProduct);

  const productType =
    sponsoredAppearanceProduct?.physicalProductType ||
    socialActivity?.physicalProductType;

  const productName =
    sponsoredAppearanceProduct?.productName || socialActivity?.productName;

  const productValue =
    sponsoredAppearanceProduct?.productValue || socialActivity?.productValue;

  const instructions =
    socialActivity?.instructions ||
    socialShareActivity?.instructions ||
    virtualAppearanceActivity?.instructions ||
    sponsoredAppearanceActivity?.target?.instructions ||
    inPersonAppearanceActivity?.instructions ||
    customActivity?.instructions;

  const possibleDates =
    inPersonAppearanceActivity || virtualAppearanceActivity
      ? inPersonAppearanceActivity?.possibleDates ||
        virtualAppearanceActivity?.possibleDates
      : null;

  const description =
    inPersonAppearanceActivity || virtualAppearanceActivity
      ? inPersonAppearanceActivity?.description ||
        virtualAppearanceActivity?.description
      : null;

  const duration =
    inPersonAppearanceActivity || virtualAppearanceActivity
      ? inPersonAppearanceActivity?.duration ||
        virtualAppearanceActivity?.duration
      : null;

  const brandName =
    socialActivity || socialShareActivity
      ? socialActivity?.brandName || socialShareActivity?.brandName
      : null;

  const website =
    socialActivity || socialShareActivity
      ? socialActivity?.website || socialShareActivity?.website
      : null;

  return (
    <div className={styles.root}>
      {brandName && (
        <div>
          <p className={styles.label}>Brand Name:</p>
          <p className={styles.value}>{brandName}</p>
        </div>
      )}

      {website && (
        <div>
          <p className={styles.label}>Website:</p>
          <p className={styles.value}>{website}</p>
        </div>
      )}

      {socialShareActivity?.socialShareDescription && (
        <div>
          <p className={styles.label}>Description:</p>
          <p
            className={styles.value}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTMLWithBreaks(
                socialShareActivity?.socialShareDescription
              ),
            }}
          />
        </div>
      )}

      {sponsoredAppearanceActivity && (
        <div>
          <p className={styles.label}>Name:</p>
          <p className={styles.value}>{sponsoredAppearanceName}</p>
        </div>
      )}

      {sponsoredAppearanceActivity && (
        <div>
          <p className={styles.label}>Bio:</p>
          <p className={styles.value}>{sponsoredAppearanceBio}</p>
        </div>
      )}

      {hasProduct && (
        <>
          <div>
            <p className={styles.label}>Physical Category/Type:</p>
            <p className={styles.value}>{`${productType}`}</p>
          </div>

          <div>
            <p className={styles.label}>Product Name:</p>
            <p className={styles.value}>{`${productName}`}</p>
          </div>

          <div>
            <p className={styles.label}>Product Value:</p>
            <p className={styles.value}>${`${productValue}`}</p>
          </div>
        </>
      )}

      {!!instructions && (
        <div>
          <p className={styles.label}>Campaign instructions:</p>
          <p
            className={styles.value}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTMLWithBreaks(instructions),
            }}
          />
        </div>
      )}

      {socialActivity && (
        <div>
          <p className={styles.label}>Encouraged:</p>
          <p
            className={styles.value}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTMLWithBreaks(
                socialActivity.encouraged ?? 'n/a'
              ),
            }}
          />
        </div>
      )}

      {socialActivity && (
        <div>
          <p className={styles.label}>Please avoid:</p>
          <p
            className={styles.value}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTMLWithBreaks(
                socialActivity.avoid ?? 'n/a'
              ),
            }}
          />
        </div>
      )}

      {socialActivity?.mediaType?.length && (
        <div>
          <p className={styles.label}>Type of media:</p>
          <p className={styles.value}>
            {socialActivity.mediaType
              ?.map((item) => item?.toLowerCase())
              .join(', ')}
          </p>
        </div>
      )}

      {socialActivity?.tasks?.length && (
        <div>
          <p className={styles.label}>Post type:</p>
          <p className={styles.value}>
            {getContentTypeText(
              socialActivity?.tasks?.map((task) => task.type)
            ).join(', ')}
          </p>
        </div>
      )}

      {socialActivity && (
        <div>
          <p className={styles.label}>Suggested caption:</p>
          <p
            className={styles.value}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTMLWithBreaks(
                socialActivity.suggestedCaption ?? 'n/a'
              ),
            }}
          />
        </div>
      )}

      {socialActivity && (
        <div>
          <p className={styles.label}>Hashtags:</p>
          <p className={styles.value}>
            {socialActivity.hashtags?.join(', ') || 'n/a'}
          </p>
        </div>
      )}

      {socialActivity && (
        <div>
          <p className={styles.label}>Profile mentions:</p>
          <p className={styles.value}>
            {socialActivity.profileMentions ?? 'n/a'}
          </p>
        </div>
      )}

      {socialActivity && (
        <div>
          <p className={styles.label}>Promotion codes:</p>
          {(socialActivity.promoCodes?.length ?? 0) > 0 ? (
            socialActivity.promoCodes?.map((item) => {
              return (
                <p key={item} className={styles.value}>
                  {item}
                </p>
              );
            })
          ) : (
            <p className={styles.value}>n/a</p>
          )}
        </div>
      )}

      {socialActivity && (
        <div>
          <p className={styles.label}>Promotion urls:</p>
          {(socialActivity.promoUrls?.length ?? 0) > 0 ? (
            socialActivity.promoUrls?.map((item) => {
              return (
                <p key={item} className={styles.value}>
                  {item}
                </p>
              );
            })
          ) : (
            <p className={styles.value}>n/a</p>
          )}
        </div>
      )}

      {!!productType && (
        <div>
          <p className={styles.label}>Product Type:</p>
          <p className={styles.value}>{productType}</p>
        </div>
      )}

      {socialActivity?.quantityInstructions && (
        <div>
          <p className={styles.label}>Quantity instructions:</p>
          <p className={styles.value}>{socialActivity.quantityInstructions}</p>
        </div>
      )}

      {socialActivity && (
        <div>
          <p className={styles.label}>Script:</p>
          <p
            className={styles.value}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTMLWithBreaks(
                socialActivity.script ?? 'n/a'
              ),
            }}
          />
        </div>
      )}

      {socialShareActivity?.shareUrl && (
        <div>
          <p className={styles.label}>Post Link:</p>
          <p className={styles.value}>{socialShareActivity.shareUrl}</p>
        </div>
      )}

      {inPersonAppearanceActivity?.country && (
        <div>
          <p className={styles.label}>Country:</p>
          <p className={styles.value}>{inPersonAppearanceActivity.country}</p>
        </div>
      )}

      {inPersonAppearanceActivity && (
        <div>
          <p className={styles.label}>State:</p>
          <p className={styles.value}> {inPersonAppearanceActivity.state}</p>
        </div>
      )}

      {inPersonAppearanceActivity && (
        <div>
          <p className={styles.label}>Zip:</p>
          <p className={styles.value}> {inPersonAppearanceActivity.zip}</p>
        </div>
      )}
      {inPersonAppearanceActivity && (
        <div>
          <p className={styles.label}>City:</p>
          <p className={styles.value}> {inPersonAppearanceActivity.city}</p>
        </div>
      )}
      {inPersonAppearanceActivity && (
        <div>
          <p className={styles.label}>Address:</p>
          <p className={styles.value}>{inPersonAppearanceActivity.address}</p>
        </div>
      )}

      {inPersonAppearanceActivity && (
        <div>
          <p className={styles.label}>Venue name:</p>
          <p className={styles.value}>{inPersonAppearanceActivity.venueName}</p>
        </div>
      )}

      {inPersonAppearanceActivity && (
        <div>
          <p className={styles.label}>Covered expenses:</p>
          <p className={styles.value}>
            {inPersonAppearanceActivity.coveredExpenses}
          </p>
        </div>
      )}

      {!!possibleDates && (
        <div>
          <p className={styles.label}>Possible Dates:</p>
          <p className={styles.value}>{possibleDates}</p>
        </div>
      )}

      {!!description && (
        <div>
          <p className={styles.label}>Description:</p>
          <p
            className={styles.value}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTMLWithBreaks(description),
            }}
          />
        </div>
      )}

      {!!duration && (
        <div>
          <p className={styles.label}>Duration:</p>
          <p className={styles.value}>{duration}</p>
        </div>
      )}

      {sponsoredAppearanceActivity && (
        <div>
          <p className={styles.label}>Video Type:</p>
          <p className={styles.value}>
            {sponsoredAppearanceActivity.videoType}
          </p>
        </div>
      )}

      {sponsoredAppearanceActivity && (
        <div>
          <p className={styles.label}>With Amplify:</p>
          <p className={styles.value}>
            {`${sponsoredAppearanceActivity.withAmplify || false}`}
          </p>
        </div>
      )}

      {sponsoredAppearanceActivity?.target && (
        <div>
          <p className={styles.label}>Target:</p>
          <p className={styles.value}>
            {sponsoredAppearanceActivity.target.type +
              ', id: ' +
              sponsoredAppearanceActivity.target.id}
          </p>
        </div>
      )}
    </div>
  );
};

export default CampaignActivityData;
